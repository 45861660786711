import React, { Fragment, useContext } from 'react';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';

export function TermsAndConditions() {
    const context = useContext(OpenChatContext);
    return (
        <Fragment>
            <div className="border border-secondary rounded p-2">
                <h2>
                    Terms of use for the Open Chat
                </h2>
                <p>
                    Your name and comments will be visible to all attendees. Please use appropriate language and content and do not share any sensitive or personal information.
                </p>
                <p>
                    Open Chat is primarily for social engagement. For further instructions related to Q&A at this event, please refer to the Welcome tab in the menu bar.
                </p>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-sm btn-primary" onClick={() => context.AcceptTerms()}>Accept</button>
                </div>
            </div>
        </Fragment>
    )
}