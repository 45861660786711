import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

export class PolicyLayout extends Component {
    static displayName = PolicyLayout.name;

    render() {
        return (
            <div className="appParent">                
                <div className="main-secondary scrollContainer">
                    <div className="policyPadding"></div>
                    <Container fluid>
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-md-11 align-self-center pl-0 pr-0">
                                <img className="logoimg" src="../images/CES_logo.png" alt="CES logo" />
                            </div>
                        </div>
                        <div className="row fillheight justify-content-center">
                            <div className="col-lg-9 col-md-11 align-self-center pl-0 pr-0">
                                {this.props.children}
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}
