import React, { Component } from 'react';
import UserContext from '../context/UserContext';
import { Redirect } from 'react-router-dom';
import authService from '../../_services/AuthService';

export class ProxyRouter extends Component {
    static displayName = ProxyRouter.name;

    constructor(props) {
        super(props);
        this.state = {
            attendingCheck: null,
            loading: true,
            proxyStatusloading: true,
            contextLoading: true

        };
    }

    componentDidMount() {
        document.title = "CESJoinIN";
        if (this.context.fullState.contextLoading === true) {
            this.checkPopulated();
        }
        if (this.context.fullState.contextLoading === false) {
            this.checkProxyStatus();
        }
        this.setState({
            contextLoading: this.context.fullState.contextLoading,
            proxyStatusloading: this.context.fullState.proxyStatusLoading,
            attendingCheck: !this.context.switches.hideAttendeeType
        })
    }

    componentDidUpdate() {

        if (this.state.contextLoading !== this.context.fullState.contextLoading) {

            this.checkProxyStatus();
            this.setState({
                contextLoading: this.context.fullState.contextLoading,
                proxyStatusloading: this.context.fullState.proxyStatusLoading,
                attendingCheck: !this.context.fullState.switches.hideAttendeeType
            })
        }
    }

    render() {
        const loggedIn = authService.loggedIn();


        if (this.state.contextLoading === true || this.state.proxyStatusLoading === true) {

            return (
                <div className="loadingOverlay">
                    <div className="loader"></div>
                </div>
            );
        }
        else if (loggedIn === true) {
            //if (this.state.attendingCheck === true && !this.context.fullState.attendingSubmitted) {
            //    return (<Redirect to="../login/Attending" />);
            //} else
            if (this.context.user.isProxy === true && !this.context.fullState.pollCardSubmitted && !this.context.user.hasAcceptedProxy) {
                return (<Redirect to="../proxy/ProxyPollCard" />);
            } else if (this.context.user.hasAppointedProxy === true && !this.context.fullState.rescindSubmitted && this.context.switches.enableProxyVoting && this.context.switches.proxySettingId === 1) {
                return (<Redirect to="../login/RescindVote" />);
            } else {
                return (<Redirect to="/" />);
            }
        } else {
            return (<Redirect to="/login/" />);
        }
    }

    checkPopulated() {
        const loggedIn = authService.loggedIn();
        if (loggedIn) {
            setTimeout(() => {
                if (!this.context.meeting.vmId) {
                    this.context.populateMeetingAndUser();
                }

            }, 1000);
        } else {

        }
    }

    checkProxyStatus() {
        this.context.getProxyStatus();
    }

}

ProxyRouter.contextType = UserContext;
