import React from 'react';
import { Route, Switch } from 'react-router-dom'
import BlankComponent from './BlankComponent';

export function LeftLayout(props) {

    if (props.showVideoStream) {
        return (
            <Switch>
                <Route path='/Video'>
                    <BlankComponent />
                </Route>
                <Route path='/DocumentList/Viewer'>
                    <div className="col-md-8 leftColumn scrollFix" >
                        {props.children}
                    </div>
                </Route>
                <Route path='/:path?'>
                    <div className="col-lg-4 col-md-6 leftColumn scrollFix" >
                        {props.children}
                    </div>
                </Route>
            </Switch>
        );
    } else {
        return (
            <div className="col-md leftColumn">
                {props.children}
            </div>
        )
    }
}



