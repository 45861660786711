import React, { Component, Fragment } from 'react';
import UserContext from './context/UserContext';

export default class Ticket extends Component {
    static displayName = Ticket.name;


    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        document.title = "CESJoinIN - Ticket";
    }


    render() {
        return (
            <Fragment>
                <div className="col-md pl-0 pr-0">
                    <div className="cloudWelcome">
                        <h2>Entry ticket</h2>
                        <p>Name: {this.context.user.userName}</p>
                        <p>Role: {this.context.user.role.replace("Member", " Member")}</p>
                    </div>

                </div>
            </Fragment>
        );
    }


}

Ticket.contextType = UserContext;