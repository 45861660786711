import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';

export default function ZoomiFrame(props) {
    const [uniqueKey, setUniqueKey] = useState(0);
    const updateKey = () => {
        setUniqueKey(uniqueKey + 1);
    }

    return (
        <div className="h-100 py-2 position-relative" key={uniqueKey}>
            <button className="btn btn-danger position-absolute top-0 end-0" onClick={updateKey}><FontAwesomeIcon icon={faRecycle}/></button>
            <iframe className="w-100 h-100 min-vh-75" src="../ZoomContainer" title="ZoomContainer" />
        </div>
    )
}