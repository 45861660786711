import React, { Component, Fragment } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FloatingLabel } from 'react-bootstrap';

export class ForgotCodes extends Component {
    static displayName = ForgotCodes.name;
    constructor(props) {
        super(props);
        this.state = {
            meetingId: '',
            guestEmail: '',
            statusId: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className="">{message}</div> });
    }

    componentDidMount() {
        document.title = "CESJoinIN - Forgot Codes"
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = async formSubmitEvent => {
        formSubmitEvent.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
        } else {
            var postMe = {
                VMId: parseInt(this.state.meetingId),
                EmailAddress: this.state.guestEmail
            }
            const response = await fetch('VMforgotCodes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postMe)
            });
            const submitted = await response.json();
            this.setState({
                statusId: submitted
            })
        }
    }
    render() {
        if (this.state.statusId === true) {
            return (
                <div className="cloud">
                    <h1 className="loginHeading">Thank you</h1><hr />
                    <div className="loginbox">
                        <p>If your details have been found you will be sent an email with your login codes.</p>
                        <p> If you do not receive an email please check your spam folder or contact your administrator.</p>
                    </div>
                </div>

            );
        }

        return (
            <Fragment>
                <form method="post" onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="">
                        <h1 className="loginHeading">Forgot Codes</h1>
                        <hr />
                        <div className="">
                            <div asp-validation-summary="All" className="text-danger"></div>
                            <div className="">
                                <div className="form-group">
                                    <FloatingLabel
                                        controlId="meetingId"
                                        label="Meeting Id"
                                        className="mb-3"
                                    >
                                        <input type="number" min="0" step="1" name="meetingId" id="meetingId" className="form-control" placeholder="Meeting Id" onChange={this.onChange} value={this.state.meetingId} onBlur={() => this.validator.showMessageFor('meetingId')} autoComplete="meetingId" required />
                                    </FloatingLabel>
                                    <div className="validationMessage">
                                        {this.validator.message('Meeting id', this.state.meetingId, 'required|integer', {
                                            messages: {
                                                required: 'The Meeting ID is required',
                                                integer: 'The Meeting ID must be a number'
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <FloatingLabel
                                        controlId="guestEmail"
                                        label="Email address"
                                        className="mb-3"
                                    >
                                        <input type="email" name="guestEmail" id="guestEmail" className="form-control" placeholder="Email address" onChange={this.onChange} value={this.state.guestEmail} onBlur={() => this.validator.showMessageFor('guestEmail')} autoComplete="email" required />
                                    </FloatingLabel>
                                    <div className="validationMessage">
                                        {this.validator.message('Email address', this.state.guestEmail, 'required|email|max:250', { messages: {} })}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group submitbutton">
                                <button type="submit" className="btn btn-primary cesbrand">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }
}