import React, { Fragment, useContext, useRef, useState, useEffect } from 'react';
import { NewPostInterface } from './NewPost';
import OpenChatContext from './OpenChatContext/OpenChatContext';
import UserContext from '../../context/UserContext';
import { OpenChatContent } from './OpenChatPost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faBackward, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TermsAndConditions } from '../TermsAndConditions';
import { MutedUser } from '../MutedUser';


//The display component for the entire chat
export function OpenChatParent(props) {
    const uContext = useContext(UserContext);
    if (!uContext.fullState.contextLoading) {
        return (
            <ChatStructure {...props}/>
        )
    } else {
        return <Fragment />
    }

}


function ChatStructure(props) {
    const context = useContext(OpenChatContext);
    const userContext = useContext(UserContext);
    const [showOpenChat, setShowOpenChat] = useState(true);
    const [atBottom, setAtBottom] = useState(true);
    const [atTop, setAtTop] = useState(true);
    const scrolled = useRef(false);

    function showHideOpenChat() {
        if (showOpenChat) {
            observer.unobserve(endOfPosts.current);
            observer.unobserve(startOfPosts.current);
        }
        setShowOpenChat(!showOpenChat);

        if (typeof (userContext.videoResize) === "function") {
            setTimeout(() => { userContext.videoResize() }, 1000)
        }
    }
    const endOfPosts = useRef(null);
    const startOfPosts = useRef(null);
    const postContainer = useRef(null);

    function scrollToBottom() {
        showOpenChat && endOfPosts.current.scrollIntoView();
        scrolled.current = false;
    }

    let postListLength = context.contextState.Posts.length;
    const previousValues = useRef({ atBottom, postListLength });

    function CheckScrollToBottom() {
        postListLength = context.contextState.Posts.length;

        if (
            (previousValues.current.atBottom &&
            previousValues.current.postListLength !== context.contextState.Posts.length) ||
            !scrolled.current

        ) {
            //if new posts have come in while you're at the bottom, scroll to bottom
            previousValues.current = { atBottom, postListLength }
            scrollToBottom();
        } else if (
            !previousValues.current.atBottom &&
            previousValues.current.postListLength !== context.contextState.Posts.length
        ) {
            //If new posts have come in while you weren't at the bottom, display more posts button
            previousValues.current = { atBottom, postListLength }
            //add "more posts" button
        } else {
            //Else just update ref
            previousValues.current = { atBottom, postListLength }
        }
    }

    useEffect(() => {
        CheckScrollToBottom();
    }, [atBottom, context.contextState.Posts.length]);


    //https://stackoverflow.com/questions/70397346/intersection-observer-with-react-js
    //check watched elements are visible on screen, if not set state to invisible
    const observer = new IntersectionObserver(
        (entries) => {
            for (let entry of entries) {
                if (entry.isIntersecting) {
                    // update the active state to the visible section
                    if (entry.target.id === "endOfPosts") {
                        setAtBottom(true);
                        scrolled.current = false;
                    } else if (entry.target.id === "startOfPosts") {
                        setAtTop(true);
                    }
                } else {
                    if (entry.target.id === "endOfPosts") {
                        setAtBottom(false);
                    } else if (entry.target.id === "startOfPosts") {
                        setAtTop(false);
                    }
                }
            }
        },
        {
            // root property defaults to the browser viewport

            // intersection ratio (90% of section must be visibile)
            threshold: 0.7
        }
    );

    useEffect(() => {
        if (atTop && !atBottom) {
            let target = document.getElementById("chatPostId" + context.minVisiblePost);
            context.OpenChatGetAllPosts().then(() => {
                target.scrollIntoView();
            })
        }
    }, [atTop])


    useEffect(() => {
        // cleanup function
        //return () => {
        //    if (showOpenChat) {
        //        observer.unobserve(endOfPosts.current);
        //        observer.unobserve(startOfPosts.current);
        //    }
        //};
        if (atTop && atBottom) {
            context.OpenChatGetAllPosts().then(() => {
                scrollToBottom();
            })
        }
    }, []);

    useEffect(() => {
        if (!scrolled.current) {
            scrollToBottom();
        }
    }, [context.contextState])

    useEffect(() => {
        scrollToBottom();
        showOpenChat && observer.observe(endOfPosts.current)
        if (showOpenChat) {
            observer.observe(endOfPosts.current);
            observer.observe(startOfPosts.current);
        }
    }, [showOpenChat])

    const onScrollEvent = () => {
        if (atBottom && scrolled.current) {
            scrolled.current = false;
        } else if (!atBottom && !scrolled.current) {
            scrolled.current = true;
        }
    }

    if (showOpenChat) {
        return (
            <div className={`openChatParentContainer ${props.showVideoStream ? 'col-lg-4' : 'noVidColumn'} leftColumn ms-0 ms-lg-3 mt-3 mt-lg-0`}>
                <div className="openChatParent d-flex flex-column ">
                    <div className="d-flex">
                        <div className="flex-grow-1 p-2">
                            <div className="h2 resize">Open Chat</div>
                            <hr/>
                        </div>
                        <div>
                            <button className="btn btn-sm" aria-label="Hide chat" title="Hide chat" onClick={() => showHideOpenChat()}><FontAwesomeIcon icon={faTimes} /></button>
                        </div>
                    </div>
                    <div onScroll={() => onScrollEvent()} className="flex-grow-1 overflow-auto" ref={postContainer} tabIndex="0">
                        <div id="startOfPosts" className="mt-1" ref={startOfPosts} />
                        <OpenChatContent showHideOpenChat={showHideOpenChat} />
                        <div id="endOfPosts" className="mb-3" ref={endOfPosts} />
                        {!atBottom && !context.replyTarget > 0 && context.contextState.AcceptTerms && <button className="btn btn-sm scrollToBottomButton py-1" title="Scroll to bottom" aria-label="scroll to bottom" onClick={() => scrollToBottom()}><FontAwesomeIcon icon={faAngleDoubleDown} /></button>}
                    </div>
                    <div className="flex-grow-0">
                        {!context.contextState.LoadingActions && (context.contextState.Muted !== "0" ?
                            <MutedUser />
                            : context.contextState.AcceptTerms ?
                                <NewPostInterface scrollToBottom={scrollToBottom} /> :
                                <TermsAndConditions />
                        )}
                    </div>
                </div>
            </div >
        )
    } else {
        return (
            <div>
                <div className="btn-chatTabParent">
                    <button className="btn btn-sm btn-chatTab border" aria-label="Show chat" title="Show chat" onClick={() => showHideOpenChat()}><FontAwesomeIcon icon={faBackward} rotation={270} />&nbsp;Open Chat</button>
                </div>
            </div>
        )
    }
}