import DOMPurify from 'dompurify';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
    }
});

const sanitiserConfig = {
    ALLOWED_ATTR: ['style', 'class', 'href', 'src', 'alt', 'title', 'colspan'],
    ALLOWED_TAGS: [
        'div',
        'strong',
        'i',
        'em',
        'a',
        's',
        'u',
        'sup',
        'sub',
        'figure',
        'table',
        'caption',
        'thead',
        'tr',
        'th',
        'tbody',
        'td',
        'ol',
        'ul',
        'li',
        'p',
        'hr',
        'h2',
        'h3',
        'h4',
        'img',
        'span'
    ],
}


export const Sanitise = (dirty) => {
    let clean = DOMPurify.sanitize(dirty, sanitiserConfig);
    return { __html: clean };
}

export const SanitisePlainText = (dirty) => {
    let clean = DOMPurify.sanitize(dirty, sanitiserConfig);
    return clean;
}