import React from 'react';



//Horizontally centred absolutely positioned out of flow
export const VoteLoader = () => <div className="voteloaderholder"><div className="loader" /></div>

//100% height and width, then centred, in flow
export const Loader = () => <div className="loaderHolder"><div className="loader" /></div>

//Fits in most buttons, in flow 
export const SmallLoader = () => <div className="loaderHolder"><div className="loader" /></div>

// inline loader
export const InlineLoader = () => <div className="inlineloader" />