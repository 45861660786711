import authService from '../../../_services/AuthService';

//Gets moderator flags
export async function OpenChatGetModeratorFlagOptions() {
    const token = authService.getToken();
    const response = await fetch('OpenChatGetModeratorFlagOptions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        //body: JSON.stringify(request)
    });

    let data = await response.json().then(data => {
        return JSON.parse(data.openChatJson);
    }).catch((error) => {
        return "Error";
    });
    return data;
}

//Add new flag to post
export async function OpenChatNewFlag(targetId, actionType) {
    let request = {
        PostId: targetId,
        ActionType: actionType
    }
    const token = authService.getToken();
    const response = await fetch('AddNewFlag', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(request)
    });

    let data = await response.json().then(response => {
        return response;
    }).catch((error) => {
        return "Error";
    });
    return data;
}