import React, { Component, Fragment } from 'react';
import UserContext from './context/UserContext';

export class Forbidden extends Component {
    static displayName = Forbidden.name;

    constructor(props) {
        super(props);
        this.state = {
            welcome: [],
            loading: true
        };
    }


    static forbiddenMessage() {
        return (
            <div className="cloudWelcome">
                <div> <h2>401 - Not Authorised!</h2><p>You are not authorised to view this page.</p> </div>
            </div>
        );

    }

    render() {
        let contents = this.context.fullState.contextLoading
            ? <Fragment>
                <div className="loader"></div> </Fragment>
            : Forbidden.forbiddenMessage(this.context.meeting);

        return (
            <div className="col-md pl-0 pr-0">                
                {contents}
            </div>
        );
    }
}

Forbidden.contextType = UserContext;
