import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from '../../_services/AuthService';
export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const tokenExists = authService.loggedIn();
        if (!tokenExists) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '../login/', state: { from: props.location } }} />
        }        
        // check if route is restricted by role
        else if (tokenExists) {
            const userRole = authService.getUserole();
            if (roles && roles.indexOf(userRole) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />
            }

            // authorised so return component
            return <Component {...props} />
        }
    }} />
)