import React, { Component } from 'react';

export class Accessibility extends Component {
    static displayName = Accessibility.name;
    componentDidMount() {
        document.title = "CESJoinIN - Accessibility"
    }

    render() {
        return (
            <div>
                <div className="cloud helppolicy">
                    <h1>Accessibility Statement for <span className="basic-information website-name">CESjoinIN</span></h1>
                    <p>
                        This is an accessibility statement from <span className="basic-information organization-name">Civica Election Services Ltd</span>.
                    </p>
                    <h2>Conformance status</h2>
                    <p>
                        The <a href="https://www.w3.org/WAI/standards-guidelines/wcag/">Web Content Accessibility Guidelines (WCAG) </a>
                        defines requirements for designers and developers to improve accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA.
                        <span className="basic-information website-name"> CESjoinIN </span> is
                        <span className="basic-information conformance-status" data-printfilter="lowercase"> partially conformant </span>
                        with
                        <span className="basic-information conformance-standard"><span data-negate=""> WCAG 2.0 level AA</span>.</span>
                        <span className="basic-information conformance-status"> Partially conformant </span>
                        means that
                        <span className="basic-information conformance-meaning"> some parts of the content do not fully conform to the accessibility standard</span>.
                    </p>
                    <h2>Feedback</h2>
                    <p>
                        We welcome your feedback on the accessibility of <span className="basic-information website-name">CESjoinIN</span>.
                        Please let us know if you encounter accessibility barriers on <span className="basic-information website-name">CESjoinIN</span>:
                    </p>
                    <ul className="basic-information feedback h-card">
                        <li>
                            E-mail: <a className="email u-email" href="mailto:support@cesjoinin.com">support@cesjoinin.com</a>
                        </li>
                        <li>
                            Postal Address: <span className="postal-address p-adr">The Election Centre, 33 Clarendon Road, N8 0NW </span>
                        </li>
                    </ul>
                    <h2>Compatibility with modern browsers and assistive technology</h2>
                    <p>
                        <span className="basic-information website-name">CESjoinIN</span> is not compatible with:
                    </p>
                    <ul className="technical-information incompatible-environments">
                        <li>Internet Explorer 11 and below</li>
                        <li>Browsers with Javascript disabled</li>
                    </ul>
                    <h2>Technical specifications</h2>
                    <p>
                        Accessibility of <span className="basic-information website-name">CESjoinIN </span>
                        relies on the following technologies to work with the particular combination of web browser and any assistive technologies or plugins installed on your computer:
                    </p>
                    <ul className="technical-information technologies-used">
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>JavaScript</li>
                    </ul>
                    <p>These technologies are relied upon for conformance with the accessibility standards used.</p>
                    <h2>Limitations and alternatives</h2>
                    <p>
                        Despite our best efforts to ensure accessibility of <span className="basic-information website-name">CESjoinIN</span>, there may be some limitations. Below is a description of known limitations, and potential solutions. Please contact us if you observe an issue not listed below.
                    </p>
                    <p>
                        Known limitations for <span className="basic-information website-name">CESjoinIN</span>:
                    </p>
                    <ol className="technical-information accessibility-limitations">
                        <li><strong>PDFs from third parties:</strong> We cannot ensure the accessibility of third party content, we monitor feedback and inform any relevant parties.</li>
                        <li><strong>Colour contrast of customer branding:</strong> Colours on the website can be altered to match customer branding. The majority of UK customers branding will be compliant with WCAG colour contrasts, however this is a customer controlled setting and we cannot guarantee it meets WCAG standards.</li>
                        <li><strong>Video content without captions:</strong> Third party streams of content may be presented through this website, not all video stream providers can embed captions. We recommend customers use streaming providers with captions.</li>
                    </ol>
                    <h2>Formal complaints</h2>
                    <p className="complaints">We’re always looking to improve the accessibility of this website. If you find any problems not listed on this page or think we’re not meeting accessibility requirements, let us know by using the email address below.<br /><br />You can contact us by email via <a className="email u-email" href="mailto:support@cesjoinin.com">support@cesjoinin.com</a></p>
                    <hr noshade="noshade" />
                    <h2>Date</h2>
                    <p>
                        This statement was created on <span className="basic-information statement-created-date">25 February 2022</span>
                    </p>
                </div>
            </div>
        );
    }
}
