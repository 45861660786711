import React, { Component, Fragment, useContext, Suspense, lazy } from 'react';
import UserContext from './context/UserContext';
import authService from './../_services/AuthService';
import Accordion from 'react-bootstrap/Accordion'
import { Row, Col, useAccordionButton } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faChevronDown, faChevronUp, faComment,  } from '@fortawesome/free-solid-svg-icons'
import { SinglePreferentialResult } from './Results';
import { NotificationManager } from 'react-notifications';
import { ContestText } from './VotingParentPage'
import { Sanitise } from '../Common/Sanitiser';


const CandidatePhoto = lazy(() => import('./VotingCandidatePhoto'));
const lazyphotofallback = <div className="photoLoaderHolder"><div className="loader" /></div>

export class PreferentialOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCandidates: {},
            voteLimitReached: false,
            submittedConfirmation: [],
            responsePending: false,
            updateWaiting: false,
            likelyNotification: false,
            pendingNotification: false,
            voteDataLoading: true
        }
        this.notificationTimer = null;
        this.ballot = this.props.ballot;
    }

    componentDidMount() {
        this.setState({
            voteDataLoading: this.context.fullState.voteDataLoading
        })
        this.mountComponent();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps && !this.context.fullState.voted) {
            let votevalue = this.props.ballot.meetingVoteAction;
            if (votevalue) {
                let prevVote;
                this.props.ballot.votingOptions.forEach(function (option) {
                    if (option.votingOption === votevalue) {
                        prevVote = option.votingValue;
                    }
                });
                this.setState({
                    voteSubmitted: prevVote
                })
            }
        }
        if (this.context.fullState.voteDataLoading !== this.state.voteDataLoading && this.state.voteDataLoading) {
            this.setState({
                voteDataLoading: this.context.fullState.voteDataLoading
            })
            this.mountComponent();
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    mountComponent = () => {
        let ballot = this.ballot;
        if (ballot.randomiseCandidates) {
            ballot.votingOptions.map(option => {
                option.voteOptionDisplayOrder = 0.5 - Math.random();
            })
        }

        let existingVotes = ballot.votingOptions.filter(option => option.isSelected === true);
        let selectedCandidates = {};
        let submittedConfirmation = [];
        if (existingVotes.length) {
            existingVotes.forEach(option => {
                selectedCandidates[option.votingOptionID.toString()] = parseInt(option.candidatePreference);
                let voteItem = {
                    candidateId: option.votingOptionID,
                    preference: parseInt(option.candidatePreference)
                }
                submittedConfirmation.push(voteItem);
            })
            this.setState({
                selectedCandidates: selectedCandidates,
                submittedConfirmation: submittedConfirmation
            })
        }
    }

    validateToSubmit = () => {
        //validation before submitting data
        let voteString = this.state.selectedCandidates;
        let ballot = this.ballot;

        //reset
        this.setState({
            voteLimitReached: false
        })
        var triggerProc = false;


        if (Object.keys(voteString).length <= ballot.maxVotes) {
            //if fewer candidates than limit voted for, submit ok
            triggerProc = true;
        } else if (Object.keys(voteString).length > ballot.maxVotes) {
            //else don't submit the vote
            this.setState({
                voteLimitReached: true
            })
            NotificationManager.warning("Your vote has NOT been updated. You may vote for up to " + ballot.maxVotes + " candidates", "Vote failed", 2000);
        }
        if (triggerProc) {
            if (this.state.responsePending) {
                this.setState({
                    updateWaiting: true
                })
            } else {
                this.singleContestSubmit();
            }
        }
    }


    singleContestSubmit = async () => {
        this.setState({
            responsePending: true,
            updateWaiting: false
        })
        let ballot = this.ballot;
        let contestID = ballot.contestID;
        let voteString = this.state.selectedCandidates;
        this.context.setArbStatus('voted', true);

        //new functionality required    
        let voteData = []


        for (const [key, value] of Object.entries(voteString)) {
            let voteItem = {
                CandidateId: parseInt(key),
                Preference: value
            }
            voteData.push(voteItem);
        }

        var ballotsubmit = {
            ContestID: contestID,
            VoteValue: voteData
        }
        const token = authService.getToken();
        const response = await fetch('VMSavePreferenceVote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(ballotsubmit)
        });
        const submitted = await response.json();
        try {
            if (submitted) {
                this.context.setContestVotedState(contestID, 1);
                this.setState({
                    submittedConfirmation: submitted
                })
                this.notification();
            }
            else {
                throw ("invalid response");
            }
        }
        catch {
            NotificationManager.error("Your vote has NOT been registered, please try and submit again", "Vote failed", 5000);
        }
        this.setState({
            responsePending: false
        })

        if (this.state.updateWaiting) {
            this.validateToSubmit();
        }
    };


    notification = () => {
        if (!this.state.likelyNotification) {
            NotificationManager.info("Your vote has been recorded", "Confirmed", 1500);
            this.setState({
                likelyNotification: true
            })
        } else {
            this.setState({
                pendingNotification: true
            })
        }
        clearTimeout(this.notificationTimer);
        this.notificationTimer = setTimeout(() => {
            this.setState({
                likelyNotification: false
            })
            if (this.state.pendingNotification) {
                NotificationManager.info("Your vote has been recorded", "Confirmed", 1500);
                this.setState({
                    pendingNotification: false
                })
            }
        }, 1505)
    }

    selectPVCandidate = (e, votingOptionID) => {
        e.stopPropagation();

        let voteString = this.state.selectedCandidates;
        let ballot = this.ballot;

        //reset
        this.setState({
            voteLimitReached: false
        })
        let okToAdd = false;
        let changed = true;


        if (Object.keys(voteString).length < ballot.maxVotes) {
            //if fewer candidates than limit voted for, submit ok
            okToAdd = true;
        }


        if (Object.keys(voteString).length > 0) {
            //find the highest and lowest ranks in the existing vote string
            let arr = Object.values(voteString);
            let min = Math.min(...arr);
            let max = Math.max(...arr);


            if (voteString[votingOptionID.toString()] > 0) {
                //if this candidate already has a rank up rank all candidates with a lower rank and remove this candidate's rank
                let thisVote = voteString[votingOptionID.toString()]
                for (const [key, value] of Object.entries(voteString)) {
                    if (value > thisVote) {
                        voteString[key] = value - 1;
                    }
                }
                delete voteString[votingOptionID.toString()]
            } else if (okToAdd) {
                //if vote limit not reached add with new lowest ranking
                voteString[votingOptionID.toString()] = max + 1
            } else {
                this.setState({
                    voteLimitReached: true
                })
                NotificationManager.warning("Your vote has NOT been updated. You may vote for up to " + ballot.maxVotes + " candidates", "Vote failed", 2000);
                changed = false;
            }

        } else {
            voteString[votingOptionID.toString()] = 1
        }
        this.setState({
            selectedCandidates: voteString
        })
        if (changed) {
            this.validateToSubmit();
        }
    }

    swapCandidates = (votingOptionID, direction) => {
        let voteString = this.state.selectedCandidates;
        let currentRank = voteString[votingOptionID.toString()];
        let arr = Object.values(voteString);
        let max = Math.max(...arr);

        if (direction === "up" && currentRank > 1) {
            let target = this.getKeyByValue(currentRank - 1);
            voteString[votingOptionID.toString()] = currentRank - 1;
            voteString[target] = currentRank;
        } else if (direction === "dn" && currentRank < max) {
            let target = this.getKeyByValue(currentRank + 1);
            voteString[votingOptionID.toString()] = currentRank + 1;
            voteString[target] = currentRank;
        }

        this.setState({
            selectedCandidates: voteString
        })
        this.validateToSubmit();
    }

    clearSelections = async () => {
        await this.setState({
            selectedCandidates: {}
        })
        this.validateToSubmit();
    }

    getKeyByValue = (value) => {
        //find the candidate id of the candidate with the specified ranking
        let voteString = this.state.selectedCandidates;
        return Object.keys(voteString).find(key => voteString[key] === value);
    }


    render() {
        let ballot = this.ballot;

        return (
            <Fragment>
                <Accordion className="accordionFullWidth mb-3">
                    <p>To cast your vote please tap the {ballot.votingFor} names in order of preference</p>
                    <Accordion flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Show further instructions</Accordion.Header>
                            <Accordion.Body>
                                {ballot.showStatement &&
                                    <p>A statement has been provided by each {ballot.votingFor}. To view each statement, click the
                                        <span className="readMore btnExpand mx-1 btn-sm d-none d-sm-inline-block d-md-none d-xl-inline-block">
                                            Statement&nbsp;
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </span>
                                        <span className="readMore btnExpand mx-1 btn-sm d-inline-block d-sm-none d-md-inline-block d-xl-none"><FontAwesomeIcon icon={faComment} />
                                        </span>
                                        button next to their name below.</p>
                                }
                                <p>You can remove or rearrange preference order by tapping on the {ballot.votingFor}'s name again or using the control buttons next to the preference display. Continue selecting {ballot.votingFor}s until you are unable to express a preference for any of the remaining {ballot.votingFor}s.</p>
                                <p>If you would like to clear your preferences and start again, please use the 'Clear selections' button.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="row my-3">
                        <div className="col-md-auto">
                            {ballot.showCountSelected &&
                                <p className={this.state.voteLimitReached ? "alert alert-danger" : ""} >
                                    You have ranked <strong>{Object.keys(this.state.selectedCandidates).length} {ballot.votingFor}{Object.keys(this.state.selectedCandidates).length !== 1 && 's'}</strong> of the {ballot.maxVotes} {ballot.maxVotes < ballot.votingOptions.length ? "permitted" : "available"}.
                                </p>
                            }
                        </div>
                        <div className="col-md-auto">
                            <button className="btn btn-secondary btn-sm" onClick={() => this.clearSelections()}>Clear selections</button>
                        </div>
                    </div>
                    {ballot.randomiseCandidates && <p>{ballot.votingFor[0].toUpperCase() + ballot.votingFor.slice(1)}s are displayed in random order.</p>}
                    <fieldset className="preferenceVote">
                        <legend className="sr-only">{ballot.title}</legend>
                        <div className="CandidateRow d-flex justify-content-between">
                            <div className="flex-grow-1 mx-3 text-center fs-5 fw-bold">
                                {ballot.votingFor[0].toUpperCase() + ballot.votingFor.slice(1)}
                            </div>
                            <div className="mx-2 text-end fs-5 fw-bold">
                                Preference
                            </div>
                        </div>
                        {ballot.votingOptions.sort((a, b) => (a.voteOptionDisplayOrder - b.voteOptionDisplayOrder)).map(option => {
                            return <CandidateBlock key={option.votingOptionID} ballot={ballot} option={option} state={this.state} swapCandidates={this.swapCandidates} selectPVCandidate={this.selectPVCandidate} />
                        })}
                    </fieldset>
                </Accordion>
            </Fragment>
        )

    }
}


function CandidateBlock(props) {
    //Display the candidate row and ordinal value for the preference vote
    let option = props.option;
    let candidateRank = props.state.selectedCandidates[option.votingOptionID.toString()];
    let ballot = props.ballot;

    const rankIt = () => {
        let candidateOrdinal = props.state.selectedCandidates[option.votingOptionID.toString()] ? props.state.selectedCandidates[option.votingOptionID.toString()].toString().slice(-1) : '';
        let candidateDoubleOrdinal = props.state.selectedCandidates[option.votingOptionID.toString()] ? props.state.selectedCandidates[option.votingOptionID.toString()].toString().slice(-2) : '';

        var response;
        if (candidateOrdinal === "1" && candidateDoubleOrdinal !== "11") {
            response = "st"
        } else if (candidateOrdinal === "2" && candidateDoubleOrdinal !== "12") {
            response = "nd"
        } else if (candidateOrdinal === "3" && candidateDoubleOrdinal !== "13") {
            response = "rd"
        } else {
            response = "th"
        }
        return response;
    }

    const disableUp = () => {
        return (!candidateRank || candidateRank === 1);
    }

    const disableDown = () => {
        let voteString = props.state.selectedCandidates;
        return (!candidateRank || candidateRank === Object.keys(voteString).length);
    }

    const checkConfirmationState = () => {
        let confirmation = props.state.submittedConfirmation;
        let thisCandConf = confirmation.filter(a => a.candidateId === option.votingOptionID);
        return (thisCandConf[0] && thisCandConf[0].preference === candidateRank);

    }

    const ariaLabel = (actionId) => {
        let votingOption = option.votingOption;
        let currentPreference;
        if (candidateRank) {
            currentPreference = candidateRank + rankIt()
        } else {
            currentPreference = "none"
        }
        let action;
        if (candidateRank) {
            switch (actionId) {
                case 1:
                    action = "remove preference";
                    break;
                case 2:
                    action = "raise preference";
                    break;
                case 3:
                    action = "lower preference";
                    break;
                default:
                    action = "";
            }
        } else {
            action = "select candidate with next available preference"
        }


        return votingOption + ", current preference: " + currentPreference + ", click action: " + action
    }


    return (
        <Fragment key={option.votingOptionID}>
            <div className={`CandidateRow  candidateIndicator ${candidateRank ? checkConfirmationState() ? 'confirmed' : 'pending' : ''}`}>
                <div className="d-flex">
                    {ballot.showPhoto && <div className="flex-grow-0 flex-shrink-0 me-1" style={{ width: '15%', maxWidth: "150px" }}>
                        <Suspense
                            fallback={lazyphotofallback}>
                            <CandidatePhoto
                                altText={option.votingOption}
                                contestId={ballot.contestID}
                                optionId={option.votingOptionID}
                                wait={option.voteOptionDisplayOrder * 50} />
                        </Suspense></div>
                    }
                    <div className="flex-grow-1">
                        <button
                            aria-label={ariaLabel(1)}
                            className={`candidateName btn w-100 h-100 text-start candidateIndicator ${candidateRank ? checkConfirmationState() ? 'confirmed' : 'pending' : ''}`}
                            title={`${candidateRank ? 'Remove' : 'Add'} preference`}
                            onClick={(e) => props.selectPVCandidate(e, option.votingOptionID)}>
                            {option.votingOption}
                        </button>
                    </div>
                    {ballot.showStatement && option.candidateStatement !== "" &&
                        <div className="mx-1"><ContextAwareToggle eventKey={option.votingOptionID} /></div>
                    }
                    <div className="mx-1">
                        <button
                            aria-label={ariaLabel(1)}
                            className={`px-0 candidatePreference btn`}
                            title={`${candidateRank ? 'Remove' : 'Add'} preference`}
                            onClick={(e) => props.selectPVCandidate(e, option.votingOptionID)}>
                            {candidateRank && <span>{candidateRank}<sup>{rankIt()}</sup></span>}
                        </button>
                    </div>
                    <div className="mx-1">
                        <div >
                            <button
                                aria-label={ariaLabel(2)}
                                disabled={disableUp()}
                                className="btn btn-light btn-sm mb-1"
                                onClick={() => props.swapCandidates(option.votingOptionID, "up")}
                                title="Raise preference">
                                <FontAwesomeIcon title="Raise preference" icon={faCaretUp} />
                            </button>
                        </div>
                        <div >
                            <button
                                aria-label={ariaLabel(3)}
                                disabled={disableDown()}
                                className="btn btn-light btn-sm"
                                onClick={() => props.swapCandidates(option.votingOptionID, "dn")}
                                title="Lower preference">
                                <FontAwesomeIcon title="Lower preference" icon={faCaretDown} />
                            </button>
                        </div>
                    </div>
                </div>

                {ballot.showStatement && option.candidateStatement !== "" &&
                    <Accordion.Collapse eventKey={option.votingOptionID}>
                        <Row className="CandidateStatement">
                            <Col>
                                <div dangerouslySetInnerHTML={Sanitise(option.candidateStatement)} />
                            </Col>
                        </Row>
                    </Accordion.Collapse>
                }
            </div>
        </Fragment>
    )
}

export class ProxyPreferentialOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        let ballot = this.props.ballot;

        if (ballot.randomiseCandidates) {
            ballot.votingOptions.map(option => {
                option.voteOptionDisplayOrder = 0.5 - Math.random();
            })
        }
    }


    render() {


        return (
            <Fragment>
                <p>Proxy Candidate list</p>
            </Fragment>
        )

    }
}



export class PreferentialResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: true
        }
    }

    render() {
        const ballot = this.props.ballot;
        return (
            <div className="cloud">
                <Accordion className="accordionFullWidth">
                    <Fragment key={ballot.resolutionIdentifier}>
                        <ContestText ballot={ballot} />
                        <SinglePreferentialResult ballot={ballot} />
                    </Fragment>
                </Accordion>
            </div>
        )
    }
}

function ContextAwareToggle({ eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    const baseStyle = {
        border: 0
    }
    const activeStyle = {
        border: 0
    }

    const buttonOpen = () => {
        return (
            <span className="readMore d-none d-sm-inline d-md-none d-xl-inline">
                Statement&nbsp;
                <FontAwesomeIcon icon={faChevronDown} />
            </span>
        )
    }
    const buttonClose = () => {
        return (
            <span className="readMore d-none d-sm-inline d-md-none d-xl-inline">
                Statement&nbsp;
                <FontAwesomeIcon icon={faChevronUp} />
            </span>
        )
    }

    return (
        <button
            type="button"
            style={isCurrentEventKey ? activeStyle : baseStyle}
            onClick={decoratedOnClick}
            className="btn btn-sm btnExpand"
            aria-label={isCurrentEventKey ? "Collapse to hide statement" : "Expand to read statement"}
        >
            {isCurrentEventKey ? buttonClose() : buttonOpen()}
            <span className="readMore d-inline d-sm-none d-md-inline d-xl-none"><FontAwesomeIcon title="statement" icon={faComment} /></span>
        </button>
    );
}

PreferentialOptions.contextType = UserContext;