export function PostData(type, userData) {

    let BaseUrl = 'api/users/';

    return new Promise((resolve, reject) => {

        fetch(BaseUrl + type, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userData)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                resolve(responseJson);
            })
            .catch((error) => {
                console.log("PostData failed trying to call " + type +" with error ");
                reject(error);
            });
    });
}