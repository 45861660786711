import React, { Component, Fragment } from 'react';
import UserContext from './context/UserContext';


export class HomeTitle extends Component {
    static displayName = HomeTitle.name;


    constructor(props) {
        super(props);
        this.state = {
            welcome: [],
            loading: true

        };

        this.renderWelcomeMessage = this.renderWelcomeMessage.bind(this)
    }

    componentDidMount() {
        this.setState({
            loading: this.context.fullState.contextLoading
        })

    }

    componentDidUpdate() {

        if (this.state.loading !== this.context.fullState.contextLoading && !this.context.fullState.contextLoading) {

            this.setState({
                loading: this.context.fullState.contextLoading
            });

        }

    }

    renderWelcomeMessage() {


        return (
            <div>
                <h1> {this.context.meeting.meetingTitle} </h1>

            </div>
        );
    }

    render() {
        let contents = (this.context.fullState.contextLoading !== false)
            ? <div>
                <h1>Welcome</h1>
            </div>
            : this.renderWelcomeMessage();

        return (
            <Fragment>
                <div>
                    {contents}
                    
                </div>
            </Fragment>
        );
    }
}

HomeTitle.contextType = UserContext;