import React, { Component, Fragment } from 'react';
import authService from '../../_services/AuthService';
import UserContext from '../context/UserContext';
import SignalRManager from '../SignalRManager'

export class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: 'Logout processing',
            isReady: false,
            loading: true,
            surveyLink: null,
            jobNo: null,
            enableSurvey: true
        };
    }
    componentDidMount() {
        document.title = "CESJoinIN - Logout";
        const isLoggedIn = authService.loggedIn();
        if (isLoggedIn) {
            if (!this.context.fullState.loading) {
                if (!!this.context.meeting.jobNo) {
                    this.setState({
                        jobNo: this.context.meeting.jobNo
                    })
                }
                this.logoutAPI();
            }

            this.setState({
                contextLoading: this.context.fullState.loading,
                enableSurvey: this.context.fullState.switches.enableSurvey
            })

        } else {
            this.setState({ isReady: true, message: "You have already left the meeting" });
        }
        this.survey();
        this.context.clearContext();
    }

    componentDidUpdate() {
        const isLoggedIn = authService.loggedIn();
        if (isLoggedIn) {
            if (this.state.contextLoading === this.context.fullState.loading) {
                if (!this.state.loading) {
                    SignalRManager.closeSR();
                    authService.logout();
                    sessionStorage.clear();
                    this.survey();
                    this.context.clearContext();
                    this.setState({ isReady: true, message: "You have left the meeting" });
                } else {
                    this.logoutAPI();
                }
            } else {
                if (!this.context.fullState.loading) {
                    this.setState({
                        contextLoading: this.context.fullState.loading
                    })
                    this.logoutAPI();
                }
            }
        }

    }

    getSurveyLink = async() => {
        try {
            const token = authService.getToken();
            const response = await fetch('api/GetSurveyLink', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const resdata = await response.json();
            if (resdata?.jsonresponse)
            {                
                return resdata.jsonresponse;
            }

        } catch {
            return null;
        }
    }

    survey = async () => {
        if (!!this.state.jobNo) {
            var baseLink = await this.getSurveyLink();
            if (baseLink) {
                var link = baseLink + "?Source=" + this.state.jobNo
                this.setState({
                    surveyLink: link
                })
            }
        }
    }


    logoutAPI = async () => {

        try {
            const token = authService.getToken();
            const response = await fetch('VMAttendeeLogout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const resdata = await response.json();
            if (resdata) {
                this.setState({
                    loading: false
                })
            }

        } catch {
            this.setState({
                loading: false
            })
        }

    }


    render() {
        const { isReady, message } = this.state;

        if (!isReady) {
            return (<div className="">
                <hr />
                {message}
            </div>);
        }
        if (!!message) {
            return (
                <div className="">
                    <hr />
                    <p>{message}</p>
                    {this.state.surveyLink && this.state.enableSurvey ?
                        <Fragment>
                            <p>
                                Please <a href={this.state.surveyLink}>leave feedback</a> on your experience.
                            </p>
                        </Fragment>
                        : ''}
                    <div className="loggedOut"><a href="../login/">Rejoin the meeting</a></div>
                </div>

            );
        }


    }
}

Logout.contextType = UserContext;
