import React, { Suspense, useMemo, useState, lazy } from "react";
import { OpenChatParent } from "./OpenChat/Common/OpenChatParent";
import ZoomiFrame from "./Zoom/ZoomiFrame";

function AudioVisual({ switches, attending }) {

    const VideoStream = lazy(() => import('./VideoStream'));
    const [forceResize, setForceResize] = useState(false);
    const meetingType = switches.meetingType;


    let showVideoStream = ((!attending && switches.enableAudioVideo) || (attending && switches.inRoomAudiovisual));
    let showOpenChat = ((!attending && switches.enableOpenChat) || (attending && switches.inRoomOpenChat));
    function toggleChat() {
        setForceResize(!forceResize)
    }

    const AudioVisualEmbed = useMemo(() => {
        return (
            <React.Fragment>
                {meetingType === 1 &&
                    <div className="col-lg" >
                        <Suspense fallback={<span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}>
                            <ZoomiFrame />
                        </Suspense>
                    </div>}

                {meetingType === 3 &&
                    <div className="col-lg" >
                        <Suspense fallback={<span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}>
                            <VideoStream />
                        </Suspense>
                    </div>}
            </React.Fragment>
        )
    }, [meetingType])

    return (
        <div className={`${showVideoStream && switches.meetingType > 0 ? 'col-md' : 'col-md-auto'} leftColumn order-1 order-md-2`}>
            <div className="d-flex flex-column flex-lg-row leftColumn">
                {showVideoStream && switches.meetingType > 0 && AudioVisualEmbed}
                {showOpenChat &&
                    <OpenChatParent showVideoStream={showVideoStream} resizeCallback={toggleChat} />
                }
            </div>
        </div>
    )
}

export default AudioVisual;