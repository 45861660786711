import React, { useContext } from 'react';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';

export function MutedUser(props) {
    let context = useContext(OpenChatContext);
    return (
        <div className="border border-secondary rounded p-2">
            You have been excluded from the chat.<br /> The reason given is:&nbsp;
            <span>{context.contextState.Muted}</span>
            </div>
        )
}