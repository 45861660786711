import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator'
import authService from '../../_services/AuthService';
import { PostData } from '../../_helpers/PostData';
import queryString from 'query-string';
import { NotificationManager } from 'react-notifications';
import UserContext from '../context/UserContext';
import { VoteLoader } from '../SecondaryComponents';
import { FloatingLabel } from 'react-bootstrap';
import { Sanitise } from '../../Common/Sanitiser';

export class Login extends Component {
    static displayName = Login.name;
    constructor(props) {
        super(props);
        this.state = {
            meetingId: '',
            securityCode1: '',
            securityCode2: '',
            isLoggedIn: false,
            directLinkGUID: '',
            submitLoading: false,
            isError: false,
            error: '',
            attendingCheck: true,
            loading: true,
            lockout: [],
            lockoutStatus: false,
            autoredirect: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className='validationalert'>{message}</div> });
        this._getLockoutStatus = null;
    }

    async componentDidMount() {
        document.title = "CESJoinIN - Login";

        await this.getLockoutStatus();
        this._getLockoutStatus = setInterval(() => this.getLockoutStatus(), 60000);
        this.populateStateFromQueryString();
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
        clearInterval(this._getLockoutStatus);
    }

    getLockoutStatus = async () => {
        await fetch('api/GetLockout'
        ).then(data => {
            return data.json();
        }).then(async response => {
            await this.setState({
                lockoutStatus: response.lockoutStatus,
                lockout: response
            })
            if (response.urlRedirect) {
                let timeOut = 10000;
                if (response.timeToRedirect > 0) {
                    timeOut = response.timeToRedirect * 1000;
                }
                setTimeout(() => {
                    this.setState({
                        autoredirect: true
                    })
                }, timeOut);
            }
        }).catch((e) => {
            console.log('an error occurred')
        })
        return;
    }


    populateStateFromQueryString() {
        const values = queryString.parse(this.props.location.search)
        if (values.vm && values.dl) {
            const data = { meetingId: values.vm, directLinkGUID: values.dl };

            PostData('authenticateGUID', data).then((result) => {
                let responseJSON = result;

                if (responseJSON.vmaUser) {
                    this.setState({
                        meetingId: values.vm,
                        securityCode1: responseJSON.vmaUser.sC1,
                        securityCode2: responseJSON.vmaUser.sC2,
                        error: '',
                        isLoggedIn: false
                    });
                }
            }).catch((error) => {
                NotificationManager.error("Login Failed", "", 3000);
                this.setState({
                    error: 'Failed Login'
                });
            });
        } else if (values.vm) {
            this.setState({
                meetingId: values.vm
            })
        }
        else { this.populateState(); }
    }

    async populateState() {
        const [isLoggedIn] = await Promise.all([authService.loggedIn()])
        this.setState({
            isLoggedIn
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, error: '' });
    }

    formatInputCode = (event) => {
        const attribute = event.target.getAttribute('name');
        this.setState({ [attribute]: event.target.value.trim() });
        this.validator.showMessageFor(attribute);
    }

    handleSubmit(e) {
        this.setState({
            submitLoading: true,
            isError: false
        });
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.setState({
                submitLoading: false
            });
        }
        else {
            const { meetingId, securityCode1, securityCode2 } = this.state;


            if (meetingId && securityCode1 && securityCode2) {
                PostData('authenticate', this.state).then((result) => {
                    let responseJSON = result;
                    if (responseJSON.token) {
                        authService.saveUserData(JSON.stringify(responseJSON));
                        this.setState({
                            isLoggedIn: true
                        });
                        this.context.populateMeetingAndUser();
                    } else if (responseJSON.value) {
                        let responseMessage = JSON.parse(responseJSON.value);
                        if (responseMessage.StatusCode === 401) {
                            this.setState({
                                isError: true,
                                error: responseMessage.Message,
                                submitLoading: false
                            });
                        }
                    } else {
                        this.setState({
                            isError: true,
                            error: "Please check your codes and try again",
                            submitLoading: false
                        });
                    }
                }).catch((error) => {
                    NotificationManager.info("Authentication failed", "Login");
                    this.setState({
                        isError: true,
                        error: "Please check your codes and try again",
                        submitLoading: false
                    });
                });
            }
        }

        e.preventDefault();
    }
    render() {
        if (this.state.isLoggedIn === true) {
            return (<Redirect to="../login/ProxyRouter" />);
        }

        const clickLoad = this.state.submitLoading;
        const isError = this.state.isError;
        const error = this.state.error;
        const displayMessage = this.state.lockout.displayMessage;

        if (this.state.loading) {
            return (
                <VoteLoader />
            )
        } else if (this.state.autoredirect === true) {
            window.location.replace(this.state.lockout.urlRedirect)
        } else if (this.state.lockoutStatus) {
            return (
                <Fragment>
                    <div className="lockOutMessage my-2">
                        <div dangerouslySetInnerHTML={Sanitise(displayMessage)} />
                    </div>
                    {
                        this.state.lockout.urlRedirect &&
                        <Fragment>
                            <p>If you are not redirected within 15 seconds, please click the button below.</p>
                            <a href={this.state.lockout.urlRedirect} className="btn btn-primary w-100 cesbrand">Go to mirror</a>
                        </Fragment>
                    }
                </Fragment >

            )
        }
        else {
            return (
                <Fragment>
                    <form onSubmit={this.handleSubmit} className="" autoComplete="off">
                        <div className="">
                            <h1 className="loginHeading">Login</h1>
                            <hr />
                            <div className="">
                                <div className="form-group">
                                    <FloatingLabel
                                        controlId="meetingId"
                                        label="Meeting ID"
                                        className="mb-3"
                                    >
                                        <input type="number"
                                            id="meetingId"
                                            title="Please enter your meeting ID"
                                            min="0" step="1"
                                            name="meetingId"
                                            className="form-control"
                                            placeholder="Meeting ID"
                                            autoFocus="autoFocus"
                                            value={this.state.meetingId}
                                            onChange={this.onChange}
                                            onBlur={this.formatInputCode}
                                            autoComplete="off"
                                            required />
                                    </FloatingLabel>
                                    <div className="validationMessage">
                                        {this.validator.message('Meeting id', this.state.meetingId, 'required|integer', {
                                            messages: {
                                                required: 'The Meeting ID is required',
                                                integer: 'The Meeting ID must be a number'
                                            }
                                        })}
                                    </div>

                                </div>
                                <div className="form-group">
                                    <FloatingLabel
                                        controlId="securityCodeOne"
                                        label="Your security code 1"
                                        className="mb-3"
                                    >
                                        <input type="text"
                                            id="securityCodeOne"
                                            title="Please enter your security code 1"
                                            name="securityCode1"
                                            className="form-control"
                                            placeholder="Your security code 1"
                                            value={this.state.securityCode1}
                                            onChange={this.onChange}
                                            onBlur={this.formatInputCode}
                                            autoComplete="off"
                                            required
                                            pattern="[A-Za-z0-9]{4,10}" />
                                    </FloatingLabel>

                                    <div className="validationMessage">
                                        {this.validator.message('Security code 1', this.state.securityCode1, 'required|alpha_num|max:10', { messages: {} })}
                                    </div>

                                </div>
                                <div className="form-group">
                                    <FloatingLabel
                                        controlId="securityCodeTwo"
                                        label="Your security code 2"
                                        className="mb-3"
                                    >
                                        <input type="text"
                                            id="securityCodeTwo"
                                            title="Please enter your security code 2"
                                            name="securityCode2"
                                            className="form-control"
                                            placeholder="Your security code 2"
                                            value={this.state.securityCode2}
                                            onChange={this.onChange}
                                            onBlur={this.formatInputCode}
                                            autoComplete="off"
                                            required pattern="[A-Za-z0-9]{1,6}" />
                                    </FloatingLabel>
                                    <div className="validationMessage">
                                        {this.validator.message('Security code 2', this.state.securityCode2, 'required|alpha_num|max:6', { messages: {} })}
                                    </div>
                                </div>
                                <p className="forgotbox">
                                    <Link to="/login/Forgot">Don't know your security code?</Link>
                                </p>

                                {isError ?
                                    <div className="validationalert">
                                        {error}
                                    </div>
                                    : ''
                                }
                                <div className="form-group submitbutton">
                                    <input type="submit" value="Join" className="btn btn-primary cesbrand" />
                                </div>

                                <p className="guestbox" >
                                    <Link to="/login/NGuest">Register as guest</Link>
                                </p>
                            </div>
                        </div>
                    </form>
                    {clickLoad ?
                        <div className="loadingOverlay">
                            <div className="loader"></div>
                        </div>
                        : ''
                    }

                </Fragment>
            );
        }
    }
}

Login.contextType = UserContext;