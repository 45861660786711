import React, { useContext, useState, useEffect } from 'react';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';


/**
 * Emoji picker for Open chat
 * */
export const EmojiPicker = (props) => {
    const context = useContext(OpenChatContext);
    const { EmojiList } = context.contextState;
    const [emojiState, setEmojiState] = useState({
        EmojiDesc: {}
    });

    const { refEmojiPicker,
        isEmojiListVisible,
        handleEmojiClick } = props;
    
    const handleEmojiMouseEnter = (emoji) => {
        setEmojiState({ EmojiDesc: emoji })
    }

    const handleEmojiMouseLeave = () => {
        //setEmojiState({ EmojiDesc:''})
    }

    useEffect(() => {
        if (EmojiList.length < 1) {
            context.OpenChatGetEmojis();
        }
    },[])



    return (
        <div ref={refEmojiPicker} className="form-floating border-0" style={{
            display: (isEmojiListVisible ? "block" : "none")
        }}>
            <div className="card">
                <div className="card-body emojiPicker">
                    <div className="d-flex flex-wrap align-content-start flex-shrink-1 flex-grow-1">
                        {
                            EmojiList.length > 0 && EmojiList.map(emoji => {
                                return (
                                    <div className="emojiButton" key={emoji.ID}>
                                        <button type="button" className="emoji-icon btn btn-sm p-0"
                                            aria-label={emoji.D}
                                            title={emoji.D}
                                            onClick={() => handleEmojiClick(emoji)}
                                            onMouseEnter={() => handleEmojiMouseEnter(emoji)}
                                            onMouseLeave={handleEmojiMouseLeave}
                                        >{(emoji.E).trim()}</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="card-footer">
                        <div>
                            <span className="emoji-icon">{emojiState.EmojiDesc.E}</span>
                            <strong>{emojiState.EmojiDesc.D}</strong>
                        </div>
                </div>
            </div>
        </div>
    )
}



export default EmojiPicker;