import React, { Component } from 'react';
import authService from '../../_services/AuthService';
import UserContext from '../context/UserContext';
import { Link } from 'react-router-dom';

export class DuplicateLogout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: 'Logout processing',
            isReady: false,
            loading: true
        };
    }
    componentDidMount() {
        document.title = "CESJoinIN - Logout"
        const isLoggedIn = authService.loggedIn();
        if (isLoggedIn) {
            if (!this.context.fullState.loading) {
                this.logoutAPI();
            }

            this.setState({
                contextLoading: this.context.fullState.loading
            })
        } else {
            this.setState({ isReady: true, message: "You are already logged out!" });
        }
        this.context.clearContext();
    }

    componentDidUpdate() {
        const isLoggedIn = authService.loggedIn();
        if (isLoggedIn) {
            if (this.state.contextLoading === this.context.fullState.loading) {
                if (!this.state.loading) {
                    authService.logout();
                    sessionStorage.clear();
                    this.context.clearContext();
                    this.setState({ isReady: true, message: "You successfully logged out!" });
                } else {
                    this.logoutAPI();
                }
            } else {
                if (!this.context.fullState.loading) {
                    this.setState({
                        contextLoading: this.context.fullState.loading
                    })
                    this.logoutAPI();
                }
            }
        }

    }


    logoutAPI = async () => {

        //DO NOT UPDATE DATABASE FOR DUPE LOGOUT
        //No API call

        this.setState({
            loading: false
        })

    }


    render() {
        const { isReady, message } = this.state;

        if (!isReady) {
            return (<div className="">
                <hr />
                <p>You have been removed by a duplicate session</p>
                {message}
            </div>);
        }
        if (!!message) {
            return (
                <div className="">
                    <hr />
                    <p>You have been removed by a duplicate session</p>
                    {message}
                    <div className="loggedOut">Click <Link to="../login/">here</Link> to rejoin</div>
                </div>

            );
        }


    }
}

DuplicateLogout.contextType = UserContext;
