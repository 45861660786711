import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import UserContext from './context/UserContext';

import authService from '../_services/AuthService';

export class PageLoadMonitor extends Component {
    static displayName = PageLoadMonitor.name;


    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.checkLoggedIn();
        this.checkPopulated();
    }


    checkPopulated() {
        const loggedIn = authService.loggedIn();
        if (loggedIn) {
            this.setState({
                loggedIn: true
            })
            setTimeout(() => {
                if (!this.context.meeting.vmId) {
                    this.context.populateMeetingAndUser();
                }
            }, 4000);
        } 
    }

    checkLoggedIn() {
        const loggedIn = authService.loggedIn();
        if (!loggedIn) {
            this.setState({
                loggedIn: false,
                loading: false
            })
        }
    }



    render() {
        const loggedIn = this.state.loggedIn;
        const loading = this.state.loading;

        if (!loggedIn && !loading) {
            return (<Redirect to="../login/" />);
        } else if (this.context.fullState.forceLogout) {
            return (<Redirect to="../login/ForcedLogout/" />);
        } else {
            return (null) 
        }               
    }

}

PageLoadMonitor.contextType = UserContext;