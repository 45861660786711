import React, { Component, Fragment } from 'react';
import UserContext from './context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import authService from './../_services/AuthService';
import { NotificationManager } from 'react-notifications';
import CloseButton from './../components/CloseButton'

var dfileURL;

export default class DocumentList extends Component {
    static displayName = DocumentList.name;


    constructor(props) {
        super(props);
        this.state = {
            docList: [],
            loading: true,
            showDocument: false,
            viewUrl: null,
            documentLoading: '',
            vmId: 0
        };

        this.viewerFrame = React.createRef();

        this.blobdownload = this.blobdownload.bind(this);
    }

    componentDidMount() {
        document.title = "CESJoinIN - Documents"
        if (this.context.fullState.contextLoading === false) {
            this.populateDocumentList();
        }
        this.setState({
            contextLoading: this.context.fullState.contextLoading
        })
        this.softRefreshListener();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate() {
        if (this.state.contextLoading != this.context.fullState.contextLoading) {
            this.populateDocumentList();
            this.setState({
                contextLoading: this.context.fullState.contextLoading
            })
        }
    }

    static selectdoc = (props) => {
        this.setState({ selecteddocument: props.documentFileName, showDocument: true })
    }

    async DocViewer(e, selectedDocument) {
        e.preventDefault();
        const token = authService.getToken();
        this.setState({ documentLoading: selectedDocument });

        this.setState({
            viewUrl: { selectedDocument },
            documentLoading: '',
            showDocument: true
        });
        this.props.history.push('/DocumentList/Viewer');
    }

    closeViewer = () => {
        this.setState({
            showDocument: false,
            viewUrl: null
        })
        this.props.history.push('/DocumentList');
    }

    softRefreshListener = () => {
        if (this.context.fullState.hubConnection) {
            this.context.fullState.hubConnection.on("srSignalAdm2Att_SoftRefresh", (message) => {
                this.populateDocumentList();
            })
        }
    }

    async blobdownload(e, selectedDocument, docFriendlyName) {
        e.preventDefault();

        // If it's not a link get the blob from the file
        const token = authService.getToken();
        this.setState({ documentLoading: selectedDocument });
        fetch('VMDownloads', {
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(selectedDocument)
        }).then((response) => response.blob())
            .then((blob) => {
                if (blob.size > 0) {
                    const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', selectedDocument);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                } else {
                    NotificationManager.error("Document download failed")
                }
                this.setState({ documentLoading: '' });
            }).catch((err) => {
                NotificationManager.error("Failed to download document");

                this.setState({
                    documentLoading: ''
                });
            });

        return dfileURL
    }

    documentViewer = () => {
        let closebutton = <div className="DocViewerTitle"><button className="btn btn-primary docBtn" type="button" onClick={() => this.closeViewer()} >Close</button></div>;
        let documentView;
        if (this.state.viewUrl) {
            documentView = <iframe title="view document" src={this.state.viewUrl.selectedDocument} type="application/pdf" />
        } else {
            documentView = <div>File not found</div>
        }

        return (
            <div className="DocViewer">
                {closebutton}
                {documentView}
            </div>
        )
    }

    downloadButton = (listdocs) => {
        let content = listdocs.documentFileName.substr(0, 6) === "https:" ?
            <a tabIndex="0"
                className="btn btn-primary docBtn"
                href={listdocs.websiteLink}
                download={listdocs.docFriendlyName}
                type="button"
                role="button"
                aria-label="Download document">
                Download
                <span className="sr-only">
                    {listdocs.docFriendlyName}
                </span>
            </a>
            : <a tabIndex="0"
                className="btn btn-primary docBtn"
                onClick={(e) => this.blobdownload(e, listdocs.documentFileName, listdocs.docFriendlyName)}
                type="button"
                role="button"
                aria-label="Download document"
                href="#">
                Download
                <span className="sr-only">
                    {listdocs.docFriendlyName}
                </span>
            </a>

        return (
            <Fragment>
                {content}
            </Fragment>
        )

    }

    documentList = () => {
        let List = this.state.docList;

        let content = List.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map(listdocs =>
            <div key={listdocs.displayOrder}>
                <div className="docBlock" >
                    <div className="documentFName">
                        <div className="dIcon"><FontAwesomeIcon icon={faFilePdf} /></div>
                        <div className="dName">
                            {listdocs.docFriendlyName}
                        </div>
                    </div>
                    <div className="docViewButton">
                        {listdocs.documentType === "pdf" && <Fragment>
                            <a
                                tabIndex="0"
                                className="btn btn-primary docBtn"
                                onClick={(e) => this.DocViewer(e, listdocs.documentFileName)}
                                type="button"
                                role="button"
                                aria-label="View document"
                                href="#">
                                {this.state.documentLoading == listdocs.documentFileName && <div className="loadingOverlay"><div className="loader"></div></div>}
                                View
                                <span className="sr-only">
                                    {listdocs.docFriendlyName}
                                </span>
                            </a>
                            {this.downloadButton(listdocs)}
                        </Fragment>}
                        {listdocs.documentType !== "pdf" &&
                            <a className="btn btn-primary docBtn"
                                href={listdocs.websiteLink}
                                target="_blank"
                                role="button"
                                aria-label="Navigate to link (opens in new window)">
                                Link
                                <span className="sr-only">
                                    {listdocs.docFriendlyName}
                                </span>
                            </a>
                        }
                    </div>
                </div>
            </div>
        )

        return (
            <Fragment>
                {content}
            </Fragment>
        )
    }


    render() {
        let List = this.state.docList

        const showdoc = this.state.showDocument
        if (this.context.fullState.contextLoading === true) {
            return (
                <div className="cloud">
                    <div className="loader" />
                </div>
            )
        }
        let contents = <Fragment>
            {showdoc ?
                this.documentViewer()
                :
                <div className="cloud">
                    <div className="d-flex justify-content-between">
                        <h2>Documents and Links</h2>
                        <CloseButton />
                    </div>
                    {this.documentList()}
                </div>
            }
        </Fragment>


        if (this.state.loading === true) {
            return (
                <div className="cloud">
                    <div className="loader" />
                </div>
            )
        }



        if (List.length === 0) {
            return (
                <div className="cloud">
                    <p>There are no documents to display.</p>
                </div>
            )
        }
        return (
            <div className="scrollContainer" >
                {contents}
            </div>
        );
    }


    async populateDocumentList() {
        const token = authService.getToken();
        const response = await fetch('VMGetDocumentList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        const data = await response.json();
        this.setState({
            docList: data,
            loading: false
        });
    }
}


DocumentList.contextType = UserContext;