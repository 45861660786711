import React, { Component } from 'react';
import { Footer } from './Footer';



export class LoginLayout extends Component {
    static displayName = LoginLayout.name;

    constructor(props) {
        super(props);
        this.state = {
            mounted: false
        };
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }
    componentWillUnmount() {
        this.setState({ mounted: false });
    }

    render() {
        return (
            <div className="appParentLogin appParent">
                <div className="main-secondary">

                    <div className="fillheight scrollFix">
                        <div className="row scrollContainer justify-content-center align-items-center">
                            <div className="col-lg-3 col-md-5 align-self-center pl-0 pr-0">
                                <div className="loginbox">
                                    <div className="loginLogo">
                                        <img src="../../images/cesjoinin_logo.png" alt="CESjoinIN" />
                                    </div>
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}
