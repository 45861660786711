import React, { Component } from 'react';
import { Footer } from './Footer';



export class ProxyLayout extends Component {
    static displayName = ProxyLayout.name;

    render() {
          return (
              <div className="appParent">
                  <div className="main-secondary">
                      <div className="fillheight scrollFix">
                          <div className="row scrollContainer justify-content-center">
                            <div className="col-lg-9 col-md-11 align-self-center pl-0 pr-0">    
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}
