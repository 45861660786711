import React, { Component, Fragment } from 'react';
import UserContext from '../context/UserContext';
import { Redirect } from 'react-router-dom';
import authService from '../../_services/AuthService';
import history from "../../utils/history";

export class Rescind extends Component {
    static displayName = Rescind.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sumitted: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = "CESJoinIN - Proxy";
        if (this.context.fullState.contextLoading === true) {
            this.checkPopulated();
        }
        this.setState({
            contextLoading: this.context.fullState.contextLoading
        });


    }

    componentDidUpdate() {
        if (this.state.contextLoading !== this.context.fullState.contextLoading) {
            this.setState({
                contextLoading: this.context.fullState.contextLoading
            })
        }
    }





    render() {
        const clickLoad = this.state.submitLoading;
        const name = this.context.user.userName;
        const contextLoading = this.state.contextLoading;
        const loading = this.state.loading;

        if (this.state.submitted === true) {
            return (<Redirect to="../login/ProxyRouter" />);
        }


        if (contextLoading === true || loading === true) {
            return (
                <div className="loadingOverlay">
                    <div className="loader"></div>
                </div>
            );
        }

        return (
            <Fragment>
                <form >
                    <div className="cloud">
                        <p>
                            Hi {name}
                        </p>
                        <p>
                            We have already received your voting instructions. Would you like to rescind these instructions and vote during today’s event or keep your existing instructions?
                    </p>
                    </div>

                    <div className="form-group submitbutton">
                        <button type="button" name="keep" onClick={() => this.handleSubmit(false)} className="btn btn-primary">Keep Existing Instructions</button>
                    </div>
                    <div className="form-group submitbutton">
                        <button type="button" name="rescind" onClick={() => this.handleSubmit(true)} className="btn btn-primary">Rescind and Recast</button>
                    </div>
                </form>

                {clickLoad ?
                    <div className="loadingOverlay">
                        <div className="loader"></div>
                    </div>
                    : ''
                }
            </Fragment>

        );
    }

    handleSubmit(value) {
        this.setState({
            loading: true
        });
        this.submitPreVoteRescind(value);
    }

    async submitPreVoteRescind(value) {
        const token = authService.getToken();
        let request = {
            Rescind : value
        }
        await fetch('VMRescindProxyVote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(request)
        }).then(response => {
            if (!response.ok) {
                throw new Error("Can't set rescind");
            }
            return response.json();
        }).then(data => {
            this.context.setArbStatus('rescindSubmitted', true);
            this.setState({
                response: data,
                loading: false,
                submitted: true
            });
            this.context.populateMeetingAndUser();
        });

    }

    checkPopulated() {
        const loggedIn = authService.loggedIn();
        if (loggedIn) {
            setTimeout(() => {
                if (!this.context.meeting.vmId) {
                    this.context.populateMeetingAndUser();
                }

            }, 2000);
        } else {
            history.push("/login/");
            let pathUrl = window.location.href;
            window.location.href = pathUrl;
        }
    }
}

Rescind.contextType = UserContext;
