import React, { Component,useContext } from 'react';
import * as signalR from '@microsoft/signalr';
import { NotificationManager } from 'react-notifications'
import authService from '../_services/AuthService';
import SignalRManager from './SignalRManager';
import { withRouter, Redirect } from "react-router-dom";
import history from '../utils/history'
import UserContext from './context/UserContext';
import OpenChatContext from './OpenChat/Common/OpenChatContext/OpenChatContext';

var voteNow = false;
var surveyNow = false;

export const SignalRContextConsumer = (props) => {
    const openChatContext = useContext(OpenChatContext);
    const userContext = useContext(UserContext);
    return <SignalRController userContext={userContext} openChatContext={openChatContext} history={props.history} />
}

export class SignalRController extends Component {
    static displayName = SignalRController.name;
    
    constructor(props) {
        super(props);

        this.state = {
            welcome: [],
            loading: true,
            user: [],
            hubConnection: null,
            hubConnectionState: "Disconnected",
            openvoting: false,
            playsound: false,
            starting: false,
            counter:0
        };

        this.buildSignalR = this.buildSignalR.bind(this);
        this.registerNotifications = this.registerNotifications.bind(this);
        this.joinGroups = this.joinGroups.bind(this);

        this.resetCounter = this.resetCounter.bind(this);
        this.connectSignalR = this.connectSignalR.bind(this);
        this.disconnectDuplicateSessions = this.disconnectDuplicateSessions.bind(this);
        this.isConnected = this.isConnected.bind(this);
        this.closeSignalR = this.closeSignalR.bind(this);
        this.audio = new Audio('./sound/notification-sound.wav');
    }

    componentDidMount() {
        this.setState({
            loading: this.props.userContext.fullState.contextLoading
        })
        SignalRManager.setupSR("from SR Comms");
        SignalRManager.addSetupListener(this.connectSignalR);
        SignalRManager.addCloseListener(this.closeSignalR);
        this.buildSignalR();
    }

    closeSignalR() {

        if (this.props.userContext.fullState.hubConnection && this.props.userContext.fullState.hubConnection.connectionState !== "Disconnected")  //0 = Connecting,1 = Connected, 2 = Reconnecting, 3=??, 4 = Disconnected
        {
            this.props.userContext.fullState.hubConnection.stop();
            SignalRManager.setConnected(false);
        }
    }


    componentWillUnmount() {
        typeof (this.connectSignalR) === "function" && SignalRManager.removeSetupListener(this.connectSignalR);
        typeof (this.closeSignalR) === "function" && SignalRManager.removeCloseListener(this.closeSignalR);

        if (this.props.userContext.fullState.hubConnection && this.props.userContext.fullState.hubConnection.connectionState !== "Disconnected")  //0 = Connecting,1 = Connected, 2 = Reconnecting, 3=??, 4 = Disconnected
        {
            this.props.userContext.fullState.hubConnection.stop();
            SignalRManager.setConnected(false);
        }

        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    isConnected() {
        return this.state.hubConnection.connectionState === "Connected";
    }

    async buildSignalR() {
        const token = await authService.getToken();
        if (this.state.hubConnection) {
            this.connectSignalR("from SignalR build");
            return;
        }
        this.setState({ hubConnectionState: "Connecting" });
        await fetch('VMGetSignalRHubURL', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: 'please can I have an address for the SignalRHub'
            }).then(response => response.json())
                .then(data => {
                    const newHubConnection = new signalR.HubConnectionBuilder()
                        .withUrl(data)
                        .configureLogging(signalR.LogLevel.Warning)
                        .withAutomaticReconnect()
                        .build();
                    this.setState({ hubConnection: newHubConnection });
                    this.props.userContext.setHubConnection(newHubConnection);
                    this.registerNotifications(newHubConnection);
                    setTimeout(() => (this.connectSignalR(newHubConnection)), 3000);
                }).catch((e) => {
                    this.setState({ hubConnectionState: "Disconnected" })
                });
        
    }

    async disconnectDuplicateSessions(uid) {
        const token = await authService.getToken();

        await fetch('VMAttendeeFLogout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ uniqueId: uid  })
        }).catch((e) => { console.log("Error with SignalR groups") });

    }

    async connectSignalR(hubConnection) {
        const loggedIn = await authService.loggedIn();
        let starting = this.state.starting;
        let vmId = this.props.userContext.meeting.vmId;
        if (typeof hubConnection === "undefined" || vmId === null) {
            console.log("called too early"); return;
        }

        const safetoConnect = loggedIn && hubConnection;
        if (safetoConnect && !starting && hubConnection.connection.connectionState === "Disconnected") {
            try {
                this.setState({ starting: true });
                await hubConnection.start()
                    .then(a => {
                        SignalRManager.setConnected(true);
                        // Immediately try and make any existing login's log out
                        this.disconnectDuplicateSessions(this.props.userContext.uidGetSet());
                        // Wait 5 seconds for the context and other items to load 1st
                        //setTimeout(() => (this.joinGroups(hubConnection)), 5000);  //PHIL W TEMP FIX - NEED TO SET A TRIGGER IN THE USERCONTEXT THAT ONCE LOADED vmID that is it safe to join the groups: Use (this.props.userContext.fullState.contextLoading === false), it sets to false once data is populated.
                        this.joinGroups(hubConnection);
                        this.setState({ starting: false });
                    })
                
            }
            catch {
                this.setState({ starting: false, counter: this.state.counter + 1 });
                console.log("JoinIN hub connection failure", this.state.counter);         
                if (this.state.counter < 20) {
                    setTimeout(() => (this.connectSignalR(hubConnection)), 3000);
                } else {
                    console.log("Stopped attempting to connect, refresh to try again");
                }
            }

            hubConnection.onreconnected(connectionId => {
                // Rejoining groups 
                this.setState({ hubConnectionState: "Connected" })
                this.joinGroups(hubConnection);
                SignalRManager.setConnected(true);
            });

            hubConnection.onclose = function () {
                SignalRManager.setConnected(false);
                this.setState({ hubConnectionState: "Disconnected" })
                try {
                    hubConnection.start().then(a => {
                        this.joinGroups(hubConnection);
                        this.registerNotifications(hubConnection);
                        this.setState({ hubConnectionState: "Connected" })
                        SignalRManager.setConnected(true);
                    })
                        .catch((err) => {
                            NotificationManager.error("Hub connection error", "SignalR", 3000);
                        });
                }
                catch (err) {
                    console.log("Connection Error");
                }
            }
        }
    }


    async registerNotifications(hubConnection) {
        hubConnection.on("srSignalAdm2Att_RefreshBrowser", (message) => {
            window.location.reload();
        });

        hubConnection.on("srSignalHub2Att_OpenChat", (message) => {
            try {
                var jsonObject = JSON.parse(message);
                this.props.openChatContext.SignalRUpdatePosts(jsonObject);
            }
            catch (e) {
                console.error("Failed to parse open chat SignalR updates")
            }
        });

        hubConnection.on("srSignalAdm2Att_MuteUser", async (value) => {
            await this.props.openChatContext.OpenChatGetMyActions();
            if (value) {
                NotificationManager.success("You have been muted", "OpenChat", 5000);
            } else {
                NotificationManager.success("You are now permitted to participate", "OpenChat", 5000);
            }
        })

        hubConnection.on("srSignalAdm2Att_SoftRefresh", (message) => {
            this.props.userContext.populateMeetingAndUser();
            //NotificationManager.success(message, "Meeting Updated", 5000); //add await if reactivating this
        });


        // Logged out by the Admin
        hubConnection.on("srSignalAdm2Att_ForceLogout", (message) => {
            this.props.userContext.setArbStatus('forceLogoutMessage', message);
            this.props.userContext.setArbStatus('forceLogout', true);
        });

        //Logged out by duplicate login
        hubConnection.on("srSignalAtt2Att_ForceLogout", (uid) => {
            if (uid !== this.props.userContext.fullState.uid) {
                history.push("/login/DuplicateLogout");
                let pathUrl = window.location.href;
                window.location.href = pathUrl;
            }
        });

        hubConnection.on("srSignalAdm2Att_ReceiveMessage", () => {
            this.props.userContext.setMessageValues(true);
            SignalRManager.popQnA();
            this.soundPlay();
        });

        hubConnection.on("srSignalAdm2Att_QuickPollUpdate", (value) => {
            SignalRManager.popQuickPoll(value);
            if(value===1 || value===3) this.soundPlay();
        });

        // Incoming alert from Admins to be displayed
        hubConnection.on("srSignalAdmToAtt_Broadcast", (message) => {
            NotificationManager.info(message, "IMPORTANT INFORMATION", 25000);
        })

        hubConnection.on("srSignalAdm2Att_PublishSurvey", (message) => {
            if (message) {
                NotificationManager.info(message, "The survey is now open", 5000);
                history.push("/Survey");
                surveyNow = message;
                SignalRManager.popSurvey();
                this.props.userContext.setSurveyPopup(true);
            }
            else {
                NotificationManager.info(message, "The survey is now closed", 5000);
                SignalRManager.popSurvey();
            }
        })

        hubConnection.on("srSignalAdm2Att_MeetingTypeSwitchUpdate", (message) => {
            SignalRManager.refreshMeetingTypeSwitches();
        })

        hubConnection.on("srSignalAdm2Att_VotePreview", async () => {
            if ((!this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.remoteVoting) || (this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.inRoomVoting)) {
                await this.props.userContext.getVoteData('SrVoting');
                NotificationManager.success("An item is now being discussed", "Voting Soon", 5000);
                SignalRManager.setVotePane(true);
            }
        });

        hubConnection.on("srSignalAdm2Att_VoteOpen", async () => {
            if ((!this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.remoteVoting) || (this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.inRoomVoting)) {
                var location = window.location.pathname; //prevent forced navigation if user is on proxy and attending pages.
                if (location.includes("/Contests")) {
                    await this.props.userContext.getVoteData('SrVoting');
                } else if (location.includes("/login/") || location.includes("/proxy/")) {
                    this.props.userContext.getVoteData('SrProxy');
                } else {
                    await this.props.userContext.getVoteData('SrVoting');
                    SignalRManager.setVotePane(true);
                    history.push("/Contests");
                    voteNow = true;
                    this.setState({ openvoting: !this.state.openvoting });
                }
                this.soundPlay();
                NotificationManager.success("An item is now open for voting", "Voting Open", 5000);
            }
        });

        hubConnection.on("srSignalAdm2Att_VotePause", () => {
            if ((!this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.remoteVoting) || (this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.inRoomVoting)) {
                var location = window.location.pathname; //correct source if user is on proxy and attending pages.
                if (location.includes("/login/") || location.includes("/proxy/")) {
                    this.props.userContext.getVoteData('SrProxy');
                } else {
                    this.props.userContext.getVoteData('SrVoting');
                }
                NotificationManager.warning("Voting has paused", "Voting Paused", 5000);
            }
        });

        hubConnection.on("srSignalAdm2Att_VoteClose", () => {
            if ((!this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.remoteVoting) || (this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.inRoomVoting)) {
                var location = window.location.pathname; //correct source if user is on proxy and attending pages.
                if (location.includes("/login/") || location.includes("/proxy/")) {
                    this.props.userContext.getVoteData('SrProxy');
                } else {
                    this.props.userContext.getVoteData('SrVoting');
                }
                NotificationManager.warning("Voting has now closed", "Voting Closed", 5000);
            }
        });

        hubConnection.on("srSignalAdm2Att_QuickPoll", (quickPollActive) => {
            SignalRManager.setQuickPoll(quickPollActive);
        });

        hubConnection.on("srSignalAdm2Att_ResultPublish", () => {
            if (this.props.userContext.switches.enableVoting && ((!this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.results) || (this.props.userContext.fullState.attendanceStatus && this.props.userContext.switches.inRoomResults))) {
                this.props.userContext.setResultNotification(true);
                NotificationManager.success("The contest result has been published", "Result Published", 5000);

            }
        });

        //This trusts the admin interlock that a resolution can't be reset while another is active
        hubConnection.on("srSignalAdm2Att_VoteReset", () => {
            var location = window.location.pathname;
            if (location.includes("/Contests") || location.includes("/Results")) {
                this.props.userContext.getVoteData('SrReset');
            }
        });

        hubConnection.on("srSignalHub2Att_PingCount", (pingDateTime) => {
            let user = this.props.userContext.user.userID;
            let vmID = user+":"+this.props.userContext.meeting.vmId;
            let pd = vmID+":" + pingDateTime;
            hubConnection.invoke("srHubAtttoHub_AttendancePing",pd);
        });
    }

    async joinGroups(hubConnection) {

        const token = await authService.getToken();
        await fetch('VMAttendeeJoinGroups', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ connectionId: hubConnection.connectionId })
        }).catch((e) => {console.log("Error with SignalR groups") });
    }

    resetCounter = () => {
        this.setState({ messageCount: 0, messageAlert: false })
    }

    resetVoteNotificationState = () => {
        this.setState({ voteNotificationState: true })
    }

    soundPlay() {
        this.audio.play();
    }

    render() {
        if (voteNow) {
            voteNow = false;
            return (<Redirect to="/Contests" />);
        }
        else if (surveyNow) {
            surveyNow = false;
            return (<Redirect to="/Survey" />);
        }
        else {
            return (
                <div />
            )
        }
    }
}

export default withRouter(SignalRController);

