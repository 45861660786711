import React from 'react';

// Return true if it is an abstain option
function abstain(votevalue) {
    var abstainoptions =
        ["ABSTAIN",
            "NO VOTE",
            "WITHHELD",
            "VOTE WITHHELD",
            "NONE",
            "ABSTAINED"
        ]
    return (abstainoptions.indexOf(votevalue.trim().toUpperCase()) === -1 ? false : true);
}

export default abstain