import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator'
import authService from '../../_services/AuthService';
import { PostData } from '../../_helpers/PostData';
import queryString from 'query-string';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import UserContext from '../context/UserContext';
import SignalRManager from '../SignalRManager'

export class LoginBypass extends Component {
    static displayName = LoginBypass.name;
    constructor(props) {
        super(props);
        this.state = {
            meetingId: '',
            securityCode1: '',
            securityCode2: '',
            isLoggedIn: false,
            directLinkGUID: '',
            submitLoading: false,
            isError: false,
            error: '',
            attendingCheck: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className='validationalert'>{message}</div> });

    }

    componentDidMount() {
        this.populateStateFromQueryString();
    }

    populateStateFromQueryString() {
        const values = queryString.parse(this.props.location.search)
        if (values.vm) {
            const data = { meetingId: values.vm, directLinkGUID: values.dl };


            // WHERE IS THE VALIDATION OF THIS INPUT BEFORE WE POST IT?? -- On the input fields, num, alpha_num, alpha_num
            PostData('authenticateGUID', data).then((result) => {
                let responseJSON = result;

                if (responseJSON.vmaUser) {
                    this.setState({
                        meetingId: values.vm,
                        securityCode1: responseJSON.vmaUser.sC1,
                        securityCode2: responseJSON.vmaUser.sC2,
                        error: '',
                        isLoggedIn: false
                    });
                }
            }).catch((error) => {
                NotificationManager.error("Login Failed", "", 3000);
                this.setState({
                    error: 'Failed Login'
                });
            });
        }
        else { this.populateState(); }
    }

    async populateState() {
        const [isLoggedIn] = await Promise.all([authService.loggedIn()])
        this.setState({
            isLoggedIn
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, error: '' });
    }

    formatInputCode = (event) => {
        const attribute = event.target.getAttribute('name');
        this.setState({ [attribute]: event.target.value.trim() });
        this.validator.showMessageFor(attribute);
    }

    handleSubmit(e) {
        this.setState({
            submitLoading: true,
            isError: false
        });
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.setState({
                submitLoading: false
            });
        }
        else {
            const { meetingId, securityCode1, securityCode2 } = this.state;


            if (meetingId && securityCode1 && securityCode2) {
                PostData('authenticate', this.state).then((result) => {
                    let responseJSON = result;
                    if (responseJSON.token) {
                        authService.saveUserData(JSON.stringify(responseJSON));
                        this.setState({
                            isLoggedIn: true
                        });
                        this.context.populateMeetingAndUser();
                    } else if (responseJSON.value) {
                        var responseMessage = JSON.parse(responseJSON.value);
                        if (responseMessage.StatusCode == 401) {
                            this.setState({
                                isError: true,
                                error: responseMessage.Message,
                                submitLoading: false
                            });
                        }
                    } else {
                        this.setState({
                            isError: true,
                            error: "Please check your codes and try again",
                            submitLoading: false
                        });
                    }
                }).catch((error) => {
                    console.error(error);
                    console.error(error.message);
                    NotificationManager.info("Authentication failed", "Login");
                    this.setState({
                        isError: true,
                        error: "Please check your codes and try again",
                        submitLoading: false
                    });
                });
            }
        }

        e.preventDefault();
    }
    render() {

        if (this.state.isLoggedIn === true) {
            return (<Redirect to="../login/ProxyRouter" />);
        }

        const clickLoad = this.state.submitLoading;
        const isError = this.state.isError;
        const error = this.state.error;

        return (
            <Fragment>
                <form onSubmit={this.handleSubmit} className="HiddenLabelForm d-none" autoComplete="off">
                    <div className="">
                        <h1 className="loginHeading">Login</h1>
                        <hr />
                        <div className="">
                            <div className="form-group">

                                <input type="number" min="0" step="1" name="meetingId" aria-label="Meeting ID" className="form-control" placeholder="Meeting ID" autoFocus="autoFocus" value={this.state.meetingId} onChange={this.onChange} onBlur={this.formatInputCode} autoComplete="on" required/>
                                <div className="validationMessage">
                                    {this.validator.message('Meeting id', this.state.meetingId, 'required|integer', {
                                        messages: {
                                            required: 'The Meeting ID is required',
                                            integer: 'The Meeting ID must be a number'
                                        }
                                    })}
                                    </div>
                                
                            </div>
                            <div className="form-group">
                                <input type="text" name="securityCode1" aria-label="Your security code 1" className="form-control" placeholder="Your security code 1" value={this.state.securityCode1} onChange={this.onChange} onBlur={this.formatInputCode} autoComplete="on" required pattern="[A-Za-z0-9]{4,10}"/>
                                <div className="validationMessage">
                                    {this.validator.message('Security code 1', this.state.securityCode1, 'required|alpha_num|max:10', { messages: {} })}
                                    </div>
                            </div>
                            <div className="form-group">                                
                                <input type="text" name="securityCode2" aria-label="Your security code 2" className="form-control" placeholder="Your security code 2" value={this.state.securityCode2} onChange={this.onChange} onBlur={this.formatInputCode} autoComplete="on" required pattern="[A-Za-z0-9]{1,6}"/>
                                <div className="validationMessage">
                                    {this.validator.message('Security code 2', this.state.securityCode2, 'required|alpha_num|max:6', { messages: {} })}
                                    </div>
                            </div>

                            {isError ?
                                <div className="validationalert">
                                    {error}
                                </div>
                                : ''
                            }
                            <div className="form-group submitbutton">
                                <input type="submit" value="Join" className="btn btn-primary cesbrand" />
                            </div>


                        </div>
                    </div>
                </form>
                {clickLoad ?
                    <div className="loadingOverlay">
                        <div className="loader"></div>
                    </div>
                    : ''
                }

            </Fragment>
        );
    }
}

LoginBypass.contextType = UserContext;