import React, { Fragment, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';
import { timeBetween } from './JsFunctions/TimeBetween';

export function FlagForModerator(props) {
    const [selected, setSelected] = useState();
    const [message, setMessage] = useState();
    const context = useContext(OpenChatContext);
    let options = context.contextState.FlagOptions;
    let post = props.post;

    async function submitFlag() {
        let submit = await context.OpenChatNewFlag(post.PostId, selected);
        if (submit === "Success") {
            await setMessage("Flag submitted successfully");
            props.handleClose();
        } else if (submit === "Reject") {
            setMessage("You have already flagged this post");
        } else {
            setMessage("An error occurred");
        }
    }

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.handleClose} className="openChatModal">
                <Fragment>
                    <Modal.Header closeButton>
                        <Modal.Title>Flag post for moderator attention</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            <div className="d-flex small">
                                <div>
                                    <strong>
                                        {post.AdminUserId > 0 ? 'Admin' : post.UserName} {post.IsPostOwner && ' (You)'}&nbsp;
                                    </strong>
                                </div>
                                <div className="text-muted small">{timeBetween(post.CreatedDateTime, post.DateTimeNow)}</div>
                            </div>
                        </div>
                        <div className="openChatReply mb-3">
                            <div className="postContent p-2">
                                {post.PostContent}
                            </div>
                        </div>

                        <fieldset>
                            <legend>
                                Please select your reason for flagging this post from the list below
                            </legend>
                            <div className="form-check">
                                {options && options.map(opt =>
                                    <label className="form-check-label d-block" key={"modoptions" + opt.ActionType} >
                                        <input
                                            className="form-check-input"
                                            type='radio'
                                            value={opt.ActionType}
                                            name="flagForModerator"
                                            checked={opt.ActionType === selected}
                                            onChange={() => setSelected(opt.ActionType)}
                                        />
                                        {opt.Description}
                                    </label>
                                )}
                            </div>
                        </fieldset>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>{message}</div>
                        <button disabled={!selected} className="btn btn-sm btn-primary" onClick={() => submitFlag()}>
                            Submit
                        </button>
                        <button className="btn btn-sm btn-secondary" onClick={props.handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Fragment>
            </Modal>
        </Fragment>
    );
}