import React, { Fragment, useEffect, useContext, useState } from 'react';
import OpenChatContext from './OpenChatContext/OpenChatContext';
import { LikeDislike } from '../LikeDislike';
import { timeBetween } from '../JsFunctions/TimeBetween';
import { ContextMenu } from '../ContextMenu';
import { SmallLoader } from '../../SecondaryComponents';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chat content container and preLoader
export function OpenChatContent(props) {
    const context = useContext(OpenChatContext);
    const posts = context.contextState.Posts;

    useEffect(() => {
        context.OpenChatGetAllPosts();
        context.OpenChatGetMyActions();
        context.GetModeratorFlagOptions();
    }, [])

    return (
        <Fragment>
            {posts && posts.length > 0 && posts.map((post) => {
                return <ChatMessage key={post.PostId} post={post} />
            })}
        </Fragment>
    )
}

function ChatMessage(props) {
    const post = props.post;

    return (
        <Fragment >
            <div id={"chatPostId" + post.PostId} className={`OpenChatPost mx-1 mb-2 p-1 ${props.level > 0 ? 'ms-' + (props.level + 3) : ''} ${post.IsPostOwner ? 'postOwner' : ''}  ${post.Hidden ? 'postHidden' : ''}`}>
                <Fragment>
                    <div className="d-flex d-flex align-items-center">
                        <div className="flex-grow-1 small">
                            <div>
                                <strong>
                                    {post.UserName}
                                </strong>
                                &nbsp;
                                <span className="small" >{timeBetween(post.CreatedDateTime, post.DateTimeNow)}</span>
                            </div>
                        </div>
                        {!post.Hidden && <LikeDislike postId={post.PostId} likes={post.Like} dislikes={post.Dislike} />}
                        {!post.Hidden && <ContextMenu post={post} />}
                    </div>
                    <div className="postContent d-flex align-items-start">
                        <div className="flex-grow-1 p-2">
                            {post.ParentPostId && post.ParentPostId > 0 ? <QuotedChatMessage postId={post.ParentPostId} /> : null}
                            <div>
                                {post.PostContent} {post.Hidden && <span>{post.HiddenBy}</span>}
                            </div>
                        </div>
                        {!post.Hidden && post.Edited && <FontAwesomeIcon className="m-2" title="Message edited" icon={faEdit} />}
                    </div>
                </Fragment>
            </div>
        </Fragment>
    )
}


export function QuotedChatMessage(props) {
    const context = useContext(OpenChatContext);
    const [extend, setExtend] = useState(false);
    const [loading, setLoading] = useState(true);
    const [postContent, setPostContent] = useState([]);

    const post = context.contextState.Posts.filter(a => a.PostId === props.postId)[0] ? context.contextState.Posts.filter(a => a.PostId === props.postId)[0] : context.contextState.Quoted.filter(a => a.PostId === props.postId)[0]

    useEffect(() => {
        if (post) {
            if (loading) {
                setLoading(false);
            }
            if (post.PostContent.length > 100) {
                setPostContent([post.PostContent.slice(0, 75), post.PostContent.slice(75)]);
            } else {
                setPostContent([post.PostContent]);
            }
        } else {
            context.OpenChatGetSinglePost(props.postId);
        }
    }, [post && post.PostId, post && post.PostContent])



    if (!loading && post) {
        return (
            <Fragment >
                <div className='postContent openChatReply mb-1 p-1'>
                    <Fragment>
                        <div>
                            <div className="small">
                                <div>
                                    <strong>
                                        {post.AdminUserId > 0 ? 'Admin' : post.UserName}
                                    </strong>
                                    &nbsp;
                                    <span className="small" >{timeBetween(post.CreatedDateTime, post.DateTimeNow)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-start">
                            <div>
                                {postContent[0]} 
                                {postContent[1] && !extend ? <Fragment>... <div className="d-flex"><div className="flex-grow-1" /><button title="Read more" aria-label="Read more" className="btn btn-sm p-0 readMoreButton" onClick={() => setExtend(true)}>Read more</button></div></Fragment> : <Fragment />}
                                {extend && postContent[1]}
                                {post.Hidden && <span> {post.HiddenBy}</span>}
                            </div>
                        </div>
                    </Fragment>
                </div>
            </Fragment>
        )
    } else {
        return (
            <SmallLoader />
        )
    }
}