import React, { Component, Fragment } from 'react';
import UserContext from './context/UserContext';
import authService from './../_services/AuthService';
import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { SingleResult } from './Results';
import { NotificationManager } from 'react-notifications';

import { ContestText } from './VotingParentPage'


export class ResolutionOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        var votevalue = this.props.ballot.meetingVoteAction;
        if (votevalue) {
            var prevVote;
            this.props.ballot.votingOptions.forEach(function (option) {
                if (option.votingOption === votevalue) {
                    prevVote = option.votingValue;
                }
            });
            this.setState({
                voteSubmitted: prevVote
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps && !this.context.fullState.voted) {
            var votevalue = this.props.ballot.meetingVoteAction;
            if (votevalue) {
                var prevVote;
                this.props.ballot.votingOptions.forEach(function (option) {
                    if (option.votingOption === votevalue) {
                        prevVote = option.votingValue;
                    }
                });
                this.setState({
                    voteSubmitted: prevVote
                })
            }
        }
    }


    selectionpChanged = type => ev => {
        this.setState({
            isVoteError: false
        })

        var highlight = ev.currentTarget.parentElement;

        var x = highlight.parentElement.parentElement.getElementsByClassName("optLabel");
        for (var i = 0, len = x.length; i < len; i++) {
            if (x[i].classList.contains("resSelectOption")) {
                x[i].classList.remove('resSelectOption');
            }
        }

        highlight.classList.add('resSelectOption');

        //v1.1 submit on select
        this.singleContestSubmit(type, ev.target.value, highlight)
    }

    singleContestSubmit = async (crID, selection, highlight) => {
        var resolution = this.props.ballot;
        var context = this.context;

        var thiscrID = resolution.contestID
        if (thiscrID === crID) {
            this.context.setArbStatus('voted', true);
            resolution.selectedOption = parseInt(selection);

            var ballotsubmit = {
                PinsID: parseInt(context.user.userID),
                ContestID: resolution.contestID,
                VoteValue: resolution.selectedOption
            }

            const token = authService.getToken();
            const response = await fetch('VMSaveResolution', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(ballotsubmit)
            });
            const submitted = await response.json();

            try {
                if (submitted[0].voteValue == selection) {
                    this.setState({
                        [crID]: selection,
                        voteSubmitted: submitted[0].voteValue
                    })
                    this.context.setContestVotedState(resolution.contestID, submitted[0].voteValue);
                    NotificationManager.info("Your vote has been recorded", "Confirmed", 3000);
                    return true;
                }
                else {
                    throw ("invalid response");
                }
            }
            catch{
                highlight.classList.remove('resSelectOption');
                NotificationManager.error("Your vote has NOT been registered, please try and submit again", "Vote failed", 5000);
            }
        }

    };

    render() {
        const ballot = this.props.ballot;

        //input name is the setting under which radio buttons group. Must be unique to this contest.
        return (
            <Fragment>
                <form className="VoteRadio">
                    <fieldset>
                        <legend className="sr-only">{ballot.title}</legend>
                        <div className="row voteOptionsRow">
                            {ballot.votingOptions.sort((a, b) => (a.voteOptionDisplayOrder - b.voteOptionDisplayOrder)).map((opt) =>
                                ballot.canVote === true && <div className="form-check col-12 col-md-4 mb-2 mb-md-0 optcolhighlight" key={opt.voteOptionDisplayOrder}>
                                    <label className={this.state.voteSubmitted === opt.votingValue ? "resConfirmOption labeloption" : "labeloption"}>

                                        <input
                                            type='checkbox'
                                            value={opt.votingValue}
                                            name={"contest" + ballot.contestID}
                                            checked={this.state[ballot.contestID] == opt.votingValue || this.state.voteSubmitted == opt.votingValue.toString() ? true : false}
                                            onChange={this.selectionpChanged(ballot.contestID)}
                                        />
                                        {opt.votingOption}
                                        {this.state.voteSubmitted == opt.votingValue.toString() ? <div className="confirmTick"><FontAwesomeIcon icon={faCheckCircle} /></div> : <div />}
                                    </label>
                                </div>
                            )}
                        </div>
                    </fieldset>
                </form>
            </Fragment>
        )
    }
}




export class ProxyOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.setState({
            discCount: this.getDiscretionaryCount(),
            discWeight: this.getDiscretionaryWeight(),
            voteSubmitted: this.getPrevVote()
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps && !this.context.fullState.voted) {
            var votevalue = this.props.ballot.meetingVoteAction;
            if (votevalue) {
                var prevVote;
                this.props.ballot.votingOptions.forEach(function (option) {
                    if (option.votingOption === votevalue) {
                        prevVote = option.votingValue;
                    }
                });
                this.setState({
                    voteSubmitted: prevVote
                })
            }
        }
    }


    getPrevVote = () => {
        var prevVote = null;
        var proxyOptions = this.props.ballot.proxyVoting;
        proxyOptions.forEach(function (option) {
            if (option.proxyVotingValue == 0) {
                prevVote = option.meetingvoteaction;
            }
        })
        return prevVote;
    }


    getDiscretionaryCount = () => {
        var discCount = 0;
        var proxyOptions = this.props.ballot.proxyVoting;
        proxyOptions.forEach(function (option) {
            if (option.proxyVotingValue == 0) {
                discCount = option.proxyVoteCount;
            }
        })
        return discCount;
    }

    getDiscretionaryWeight = () => {
        var proxyWeight = 0;
        var proxyOptions = this.props.ballot.proxyVoting;
        proxyOptions.forEach(function (option) {
            if (option.proxyVotingValue == 0) {
                proxyWeight = option.proxyWeight;
            }
        })
        return proxyWeight;
    }

    selectionChanged = type => ev => {
        this.setState({
            isVoteError: false
        })

        var highlight = ev.currentTarget.parentElement;

        var x = highlight.parentElement.parentElement.getElementsByClassName("optLabel");
        for (var i = 0, len = x.length; i < len; i++) {
            if (x[i].classList.contains("resSelectOption")) {
                x[i].classList.remove('resSelectOption');
            }
        }

        highlight.classList.add('resSelectOption');

        this.proxyContestSubmit(type, ev.target.value, highlight)
    }

    proxyContestSubmit = async (crID, selection, highlight) => {
        var resolution = this.props.ballot;
        var context = this.context;

        var thiscrID = resolution.contestID
        if (thiscrID === crID) {
            this.context.setArbStatus('voted', true);
            resolution.selectedOption = parseInt(selection);

            var ballotsubmit = {
                PinsID: parseInt(context.user.userID),
                ContestID: resolution.contestID,
                VoteValue: resolution.selectedOption
            }

            const token = authService.getToken();

            //TO DO - setup proxy submit
            const response = await fetch('VMSaveProxyResolution', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(ballotsubmit)
            });
            const submitted = await response.json();


            try {
                if (submitted[0].voteValue == selection) {
                    this.setState({
                        [crID]: selection,
                        voteSubmitted: submitted[0].voteValue
                    })
                    NotificationManager.info("Proxy vote selection has been updated", "Confirmed", 3000);
                    return true;
                }
                else if (submitted[0].voteValue == -1) {
                    NotificationManager.warning("You are no longer an appointed proxy for this contest", "Rescinded", 5000);
                    this.setState({
                        discCount: 0,
                        discWeight: 0
                    })
                }
                else {
                    throw ("invalid response");
                }
            }
            catch{
                highlight.classList.remove('resSelectOption');
                NotificationManager.error("Proxy vote has NOT been registered, please try and submit again", "Vote failed", 5000);
            }

        }
    };


    render() {
        const ballot = this.props.ballot;
        const discCount = this.state.discCount;
        const discWeight = this.state.discWeight;

        return (
            <Fragment>
                <form className="VoteRadio">

                    <div className="proxyCloud">
                        <h5>Proxy vote</h5>
                    You have {discCount} discrectionary vote{discCount !== 1 ? 's' : ''} to submit for this resolution. {ballot.publishTypeId !== 1 && 'The total discretionary vote weight is ' + discWeight + '.'}
                        {discCount > 0 ?
                            <fieldset>
                                <div className="row voteOptionsRow">
                                    <legend className="sr-only">Proxy vote: {ballot.title}</legend>
                                    {ballot.votingOptions.sort((a, b) => (a.voteOptionDisplayOrder - b.voteOptionDisplayOrder)).map((opt) =>
                                        <div className="form-check col-12 col-md-4 mb-2 mb-md-0 optcolhighlight" key={opt.voteOptionDisplayOrder}>
                                            <label className={this.state.voteSubmitted == opt.votingValue.toString() ? "resConfirmOption labeloption" : "labeloption"}>
                                                <input
                                                    type='checkbox'
                                                    selected value={opt.votingValue}
                                                    checked={this.state[ballot.contestID] == opt.votingValue}
                                                    name={opt.votingOption}
                                                    onChange={this.selectionChanged(ballot.contestID)}
                                                />
                                                {opt.votingOption}
                                                {this.state.voteSubmitted == opt.votingValue.toString() ? <div className="confirmTick"><FontAwesomeIcon icon={faCheckCircle} /></div> : <div />}
                                            </label>
                                        </div>
                                    )}
                                   </div>
                               </fieldset>
                            : ''}
                    </div>

                </form>
            </Fragment>
        )
    }
}

export class ResolutionResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: true
        }
    }


    render() {
        const ballot = this.props.ballot;
        return (
            <div className="cloud">
                <Accordion className="accordionFullWidth">
                    <Fragment key={ballot.resolutionIdentifier}>
                        <ContestText ballot={ballot} />
                        <SingleResult ballot={ballot} />
                    </Fragment>
                </Accordion>
            </div>
        )
    }
}


ProxyOptions.contextType = UserContext;
ResolutionOptions.contextType = UserContext;