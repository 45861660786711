import { EventEmitter } from 'events';
import UserContext from './context/UserContext';

const SRConstants = {
    SETUP: 'setup',
    CLOSE: 'close',
    CONNECTED: 'connected',
    MESSAGES: 'messages',
    RECVMESSAGES: "recvmessages",
    VOTEPANE: 'votepane',
    QUICKPOLL: 'quickpoll',
    SURVEY: 'survey',
    SWITCHES: 'switches'
}

let srHubConnection = false;

export class SignalRManager extends EventEmitter {
    /************* Used for AskAQuestion messages **********/
    addQuickPollListener(callback) {
        this.addListener(SRConstants.QUICKPOLL, callback);
        return;
    }

    removeQuickPollListener(callback) {
        this.removeListener(SRConstants.QUICKPOLL, callback);
        return;
    }

    addSurveyListener(callback) {
        this.addListener(SRConstants.SURVEY, callback);
        return;
    }

    removeSurveyListener(callback) {
        this.removeListener(SRConstants.SURVEY, callback);
        return;
    }

    popSurvey() {
        this.emit(SRConstants.SURVEY, "pop Survey");
        return;
    }

    addMeetingTypeListener(callback) {
        this.addListener(SRConstants.SWITCHES, callback);
        return;
    }

    removeMeetingTypeListener(callback) {
        this.removeListener(SRConstants.SWITCHES, callback);
        return;
    }

    refreshMeetingTypeSwitches() {
        this.emit(SRConstants.SWITCHES, "refresh meeting type switches");
        return;
    }

    addReceiveMessageListener(callback) {
        this.addListener(SRConstants.RECVMESSAGES, callback);
        return;
    }

    popQnA() {
        this.emit(SRConstants.RECVMESSAGES, "set up SignalR");
        return;
    }

    /**
     * ************* Vote Pane Window Popper ************
     * @param {any} callback
    */


    setVotePaneListener(callback) {
        this.addListener(SRConstants.VOTEPANE, callback);
        return;
    }

    removeVotePaneListener(callback) {
        this.removeListener(SRConstants.VOTEPANE, callback);
        return;
    }

    popQuickPoll(value) {
        this.emit(SRConstants.QUICKPOLL, value);
        return;
    }

    setVotePane(value) {
        this.emit(SRConstants.VOTEPANE, value);
        return;
    }

    setQuickPoll(quickPollActive) {
        this.emit(SRConstants.QUICKPOLL, quickPollActive);
        return;
    }

    /************ Used to start the SignalR Connections **********/

    addSetupListener(callback) {
        this.addListener(SRConstants.SETUP, callback);
        return;
    }

    removeSetupListener(callback) {
        this.removeListener(SRConstants.SETUP, callback);
        return;
    }

    setupSR(message) {
        this.emit(SRConstants.SETUP, "set up SignalR");
        return;
    }

    addCloseListener(callback) {
        this.addListener(SRConstants.CLOSE, callback);
        return;
    }

    removeCloseListener(callback) {
        this.removeListener(SRConstants.CLOSE, callback);
        return;
    }

    closeSR(message) {
        this.emit(SRConstants.CLOSE, "close SignalR");
        return;
    }
    
    /**
     * ********* Used to detect SignalR connection state changes ******
     * @param {any} callback
     */
    addConnectionListener(callback) {
        this.addListener(SRConstants.CONNECTED, callback);
        return;
    }

    removeConnectionListener(callback) {
        this.removeListener(SRConstants.CONNECTED, callback);
        return;
    }

    setConnected(value) {
        srHubConnection = value;
        this.emit(SRConstants.CONNECTED, value);
        return;
    }

    isConnected() {
        return srHubConnection;
    }

}

SignalRManager.contextType = UserContext;

export default new SignalRManager();