import React, { Fragment, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom';
import UserContext from './context/UserContext';


const CloseButton = () => {
    const history = useHistory();
    const context = useContext(UserContext);
    let attending = context.fullState.attendanceStatus;
    let switches = context.switches;
    let showVideoStream = ((!attending && switches.enableAudioVideo) || (attending && switches.inRoomAudiovisual));

    if (showVideoStream) {
        return (
            <button title="Close window" className="btn" onClick={() => history.push("/Video")}><FontAwesomeIcon icon={faWindowClose} /></button>
        )
    }
    else {
        return (<Fragment />)
    }
}

export default CloseButton;

