import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { PostData } from '../../_helpers/PostData';
import SimpleReactValidator from 'simple-react-validator';
import { FloatingLabel } from 'react-bootstrap';

export class CreateGuestUser extends Component {
    static displayName = CreateGuestUser.name;
    constructor(props) {
        super(props);
        this.state = { meetingId: '', guestName: '', guestEmail: '', statusId: null };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className="">{message}</div> });
    }
    componentDidMount() {
        document.title = "CESJoinIN - Register as guest"
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit(e) {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
        } else {
            const { meetingId, guestName, guestEmail } = this.state;
            if (meetingId && guestName && guestEmail) {
                PostData('guestSignup', this.state).then((result) => {
                    let responseJSON = result;
                    if (responseJSON.status !== null) {
                        this.setState({
                            statusId: responseJSON.status
                        });
                    } else {

                        console.log('Guest creation failure')
                    }
                });
            }
        }


        e.preventDefault();
    }
    render() {
        if (this.state.statusId === 1) {
            return (
                <div>
                    <h1>Registration successful</h1>
                    <hr />
                    <div>You have been assigned security codes for the meeting. An email will be sent to your mailbox with instructions to login.</div>
                </div>

            );
        }

        if (this.state.statusId === -1) {
            return (
                <div>
                    <h1>Registration issue</h1>
                    <hr />
                    <div>It appears you have already registered. Please contact your administrator, alternatively use the links below as appropriate</div>
                    <ul>
                        <li><Link to="../login/Forgot"> Forgot codes</Link></li>
                        <li><Link to="../login/">Login</Link></li>
                    </ul>
                </div>
            );
        }
        else if (this.state.statusId === -2 || this.state.statusId === 0) {
            return (
                <div>
                    <h1>Registration issue</h1>
                    <hr />
                    <div>Sorry, we cannot complete this registration. Please contact your administrator.</div>
                </div>
            );
        }
        else if (this.state.statusId === -3) {
            return (
                <div>
                    <h1>Registration issue</h1>
                    <hr />
                    <div>Sorry, this meeting has ended.</div>
                </div>
            );
        }
        else if (this.state.statusId === -4) {
            return (
                <div>
                    <h1>Registration issue</h1>
                    <hr />
                    <div>Sorry, this meeting does not allow guests.</div>
                </div>
            );
        }
        return (
            <Fragment>
                <form method="post" onSubmit={this.handleSubmit} >
                    <div className="">
                        <h1 className="loginHeading">Guest Registration</h1>
                        <hr />
                        <div className="">
                            <div asp-validation-summary="All" className="text-danger"></div>
                            <div className="form-group">
                                <div className="form-group">
                                    <FloatingLabel
                                        controlId="meetingId"
                                        label="Meeting Id"
                                        className="mb-3"
                                    >
                                        <input type="number" min="0" step="1" name="meetingId" id="meetingId" className="form-control" placeholder="Meeting Id" onChange={this.onChange} value={this.state.meetingId} onBlur={() => this.validator.showMessageFor('meetingId')} autoComplete="on" required />
                                    </FloatingLabel>
                                    <div className="validationMessage">
                                        {this.validator.message('Meeting id', this.state.meetingId, 'required|integer', {
                                            messages: {
                                                required: 'The Meeting ID is required',
                                                integer: 'The Meeting ID must be a number'
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <FloatingLabel
                                        controlId="fullName"
                                        label="Full name"
                                        className="mb-3"
                                    >
                                        <input type="text" name="guestName" id="fullName" className="form-control" placeholder="Full name" onChange={this.onChange} value={this.state.guestName} onBlur={() => this.validator.showMessageFor('guestName')} autoComplete="on" required />
                                    </FloatingLabel>
                                    <div className="validationMessage">
                                        {this.validator.message('Full name', this.state.guestName, 'required|max:250', { messages: {} })}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <FloatingLabel
                                        controlId="emailAddress"
                                        label="Email address"
                                        className="mb-3"
                                    >
                                        <input type="email" name="guestEmail" id="emailAddress" className="form-control" placeholder="Email address" onChange={this.onChange} value={this.state.guestEmail} onBlur={() => this.validator.showMessageFor('guestEmail')} autoComplete="on" required />
                                    </FloatingLabel>
                                    <div className="validationMessage">
                                        {this.validator.message('Email address', this.state.guestEmail, 'required|email|max:250', { messages: {} })}
                                    </div>

                                </div>
                            </div>
                            <div className="form-group submitbutton">
                                <button type="submit" className="btn btn-primary cesbrand">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                </form>
            </Fragment>

        );
    }

}