import React, { Component, Fragment, useContext, Suspense, lazy } from 'react';
import UserContext from './context/UserContext';
import authService from './../_services/AuthService';
import Accordion from 'react-bootstrap/Accordion'
import { Row, Col } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faComment, faTimes } from '@fortawesome/free-solid-svg-icons'
import { SingleXvoteResult } from './Results';
import { NotificationManager } from 'react-notifications';
import { ContestText } from './VotingParentPage'
import { Sanitise } from '../Common/Sanitiser';


const CandidatePhoto = lazy(() => import('./VotingCandidatePhoto'));
const lazyphotofallback = <div className="photoLoaderHolder"><div className="loader" /></div>

export class XvoteOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCandidates: {},
            voteLimitReached: false
        }

        this.ballot = this.props.ballot;
        this.voteCount = 0;
        this.voteString = '';
    }


    componentDidMount() {
        let ballot = this.ballot;
        if (ballot.randomiseCandidates) {
            ballot.votingOptions.map(option => {
                option.voteOptionDisplayOrder = 0.5 - Math.random();
            })
        }
        let existingVotes = ballot.votingOptions.filter(option => option.isSelected === true);
        let oldstate = this.state.selectedCandidates;
        if (existingVotes.length) {
            existingVotes.map(option => {
                oldstate[option.votingOptionID.toString()] = true;
            })

            this.setState({
                selectedCandidates: oldstate
            })
            this.voteCount = existingVotes.length;
        }


    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps && !this.context.fullState.voted) {
            var votevalue = this.props.ballot.meetingVoteAction;
            if (votevalue) {
                var prevVote;
                this.props.ballot.votingOptions.forEach(function (option) {
                    if (option.votingOption === votevalue) {
                        prevVote = option.votingValue;
                    }
                });
                this.setState({
                    voteSubmitted: prevVote
                })
            }
        }
    }

    selectCandidate = (e, optionID) => {
        e.stopPropagation() //prevents the click from acting on the x and the div causing a double trigger
        this.setState({
            voteLimitReached: false
        })
        const value = !!!this.state.selectedCandidates[optionID.toString()];
        let oldstate = this.state.selectedCandidates;

        var triggerProc = false;
        //radiobutton the single-x
        if (this.ballot.maxVotes === 1) {
            oldstate = { [optionID.toString()]: value };
            triggerProc = true;
        }
        //multix but only of below vote limit
        else if ((this.voteCount < this.ballot.maxVotes) || (!value && this.voteCount >= this.ballot.maxVotes)) {
            oldstate[optionID.toString()] = value
            triggerProc = true;
        } else if (this.voteCount >= this.ballot.maxVotes) {
            this.setState({
                voteLimitReached: true
            })
            NotificationManager.warning("Your vote has NOT been updated. You may vote for up to " + this.ballot.maxVotes + " candidates", "Vote failed", 2000);
        }
        this.setState({
            selectedCandidates: oldstate
        })
        this.voteCount = Object.keys(oldstate).filter(key => oldstate[key] === true).length;
        this.voteString = Object.keys(oldstate).filter(key => oldstate[key] === true).toString();

        if (triggerProc) {
            this.singleContestSubmit();
        }

    }


    singleContestSubmit = async () => {
        var contest = this.props.ballot;

        var contestID = contest.contestID


        if (this.voteCount <= this.ballot.maxVotes) {
            this.context.setArbStatus('voted', true);
            var ballotsubmit = {
                ContestID: contestID,
                VoteValue: this.voteString
            }
            const token = authService.getToken();
            const response = await fetch('VMSaveXvote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(ballotsubmit)
            });
            const submitted = await response.json();
            try {
                if (submitted[0].voteValue === "1" || (submitted[0].voteValue === "0" && this.voteCount === 0)) {
                    this.setState({
                        voteSubmitted: submitted[0].voteValue
                    })
                    this.context.setContestVotedState(contestID, submitted[0].voteValue);
                    NotificationManager.info("Your vote has been recorded", "Confirmed", 2000);
                    return true;
                }
                else {
                    throw ("invalid response");
                }
            }
            catch {
                NotificationManager.error("Your vote has NOT been registered, please try and submit again", "Vote failed", 5000);
            }
        }

    };

    render() {
        let ballot = this.ballot;

        return (
            <Fragment>
                <Accordion className="accordionFullWidth">
                    {ballot.showCountSelected &&
                        <p className={this.state.voteLimitReached ? "alert alert-danger" : ""} >You have selected <strong>{this.voteCount} {ballot.votingFor}{this.voteCount !== 1 && 's'}</strong>.</p>
                    }
                    {ballot.randomiseCandidates && <p> {ballot.votingFor[0].toUpperCase() + ballot.votingFor.slice(1) }s are displayed in random order.</p>}
                    <fieldset>
                        <legend className="sr-only">{ballot.title}</legend>
                        {ballot.votingOptions.sort((a, b) => (a.voteOptionDisplayOrder - b.voteOptionDisplayOrder)).map(option =>
                            <Fragment key={option.votingOptionID}>
                                <div className="CandidateRow">
                                    <div className="d-flex">
                                        {ballot.showPhoto &&
                                            <div className="me-1" style={{ width: '15%', maxWidth: "150px" }}>
                                                <Suspense fallback={lazyphotofallback}>
                                                    <CandidatePhoto altText={option.votingOption} contestId={ballot.contestID} optionId={option.votingOptionID} wait={option.voteOptionDisplayOrder * 50} />
                                                </Suspense>
                                            </div>
                                        }
                                        <div className="flex-grow-1">
                                            <label className="candidateName" htmlFor={"contestId" + ballot.contestID + "voteOptionId" + option.votingOptionID}>{option.votingOption}</label>
                                        </div>
                                        {ballot.showStatement && option.candidateStatement !== "" &&
                                            <div className="mx-1">
                                                <ContextAwareToggle eventKey={option.votingOptionID} />
                                            </div>
                                        }
                                        <div className="xboxparent px-0" style={{ width: '40px' }}>
                                            <div className="xbox"
                                                name={option.votingOption}
                                                onClick={(e) => this.selectCandidate(e, option.votingOptionID)}
                                                id={option.votingOptionID + "xbox"}
                                            >
                                                {!!this.state.selectedCandidates[option.votingOptionID.toString()] && <FontAwesomeIcon onClick={(e) => this.selectCandidate(e, option.votingOptionID)} icon={faTimes} />}
                                            </div>
                                            <input type="checkbox"
                                                name={option.votingOption}
                                                checked={!!this.state.selectedCandidates[option.votingOptionID.toString()]}
                                                onChange={(e) => this.selectCandidate(e, option.votingOptionID)}
                                                id={"contestId" + ballot.contestID + "voteOptionId" + option.votingOptionID}
                                            />
                                        </div>
                                    </div>

                                    {ballot.showStatement && option.candidateStatement !== "" &&
                                        <Accordion.Collapse eventKey={option.votingOptionID}>
                                            <Row className="CandidateStatement">
                                                <Col>
                                                    <div dangerouslySetInnerHTML={Sanitise(option.candidateStatement)} />
                                                    {/*option.candidateStatement */}
                                                </Col>
                                            </Row>
                                        </Accordion.Collapse>
                                    }
                                </div>

                            </Fragment>
                        )}
                    </fieldset>
                </Accordion>
            </Fragment>
        )

    }
}

export class ProxyXvoteOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        let ballot = this.props.ballot;

        if (ballot.randomiseCandidates) {
            ballot.votingOptions.map(option => {
                option.voteOptionDisplayOrder = 0.5 - Math.random();
            })
        }
    }


    render() {


        return (
            <Fragment>
                <p>Proxy Candidate list</p>
            </Fragment>
        )

    }
}



export class XvoteResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: true
        }
    }

    render() {
        const ballot = this.props.ballot;
        return (
            <div className="cloud">
                <Accordion className="accordionFullWidth">
                    <Fragment key={ballot.resolutionIdentifier}>
                        <ContestText ballot={ballot} />
                        <SingleXvoteResult ballot={ballot} />
                    </Fragment>
                </Accordion>
            </div>
        )
    }
}

function ContextAwareToggle({ eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    const baseStyle = {
        border: 0
    }
    const activeStyle = {
        border: 0
    }

    const buttonOpen = () => {
        return (
            <span className="readMore d-none d-sm-inline d-md-none d-xl-inline">
                Statement&nbsp;
                <FontAwesomeIcon icon={faChevronDown} />
            </span>
        )
    }
    const buttonClose = () => {
        return (
            <span className="readMore d-none d-sm-inline d-md-none d-xl-inline">
                Statement&nbsp;
                <FontAwesomeIcon icon={faChevronUp} />
            </span>
        )
    }

    return (
        <button
            type="button"
            style={isCurrentEventKey ? activeStyle : baseStyle}
            onClick={decoratedOnClick}
            className="btn btn-sm btnExpand"
            aria-label={isCurrentEventKey ? "Collapse to hide statement" : "Expand to read statement"}
        >
            {isCurrentEventKey ? buttonClose() : buttonOpen()}
            <span className="readMore d-inline d-sm-none d-md-inline d-xl-none"><FontAwesomeIcon title="statement" icon={faComment} /></span>
        </button>
    );
}

XvoteOptions.contextType = UserContext;