import React, { Component } from 'react';

export default class BlankComponent extends Component {
    static displayName = BlankComponent.name;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = this.props.title ? this.props.title : '';
    }

    render() {
        return (
            <></>
        );
    }
}

export function BlankFunction(props) {
    return (
        <></>
    );
}


