import React, { Component } from 'react';

export class Privacy extends Component {
    static displayName = Privacy.name;
    componentDidMount() {
        document.title = "CESJoinIN - Privacy Policy"
    }

    render() {
        return (
            <div>
                <div className="cloud">
                    <h1>Privacy, Security and Copyright Statement</h1>
                    <p>This website is hosted and maintained by Civica Election Services (CES), 33 Clarendon Road, London N8 0NW and is provided in our role as a data processor. Civica Election Services (CES) is a trading name of Civica Election Services Limited. Our data protection officer can be contacted via compliance@civica.co.uk.
                    </p>
                    <p>
                        The data controller, who has determined the purposes for which, and the manner in which, personal information (data) is collected and processed using this website, is the organisation for whom this joinIN event is being administered by CES. Details of their data protection officer can be found on their own website or alternatively they can be contacted via the CES, Data Protection Officer (compliance@civica.co.uk).
                    </p>
                    <h2>
                        How and why is information gathered on this site used?
                    </h2>
                    <p>
                        A joinIN event is any event, meeting or other electoral related project that CES is administering on behalf of a data controller.
                    </p>
                    <p>
                        The purpose of the event site is to allow the attendees in a joinIN event to:
                    </p>
                    <ul>
                        <li>
                            obtain information about the event,
                        </li>
                        <li>
                            if relevant and enabled, view a live stream of the event,
                        </li>
                        <li>
                            if relevant and enabled, cast votes electronically on polls called at the event,
                        </li>
                        <li>
                            if relevant and enabled, submit a question to the organisers of the event.
                        </li>
                    </ul>
                    <p>
                        Attendees are the individuals deemed eligible to participate in the joinIN event by the data controller.
                    </p>
                    <p>
                        The legal basis for the use of your personal information will typically be one of the following reasons:
                    </p>
                    <ol>
                        <li>
                            the data controller needs to process your personal information to satisfy their legal obligations, e.g. general meetings for companies, building societies and other mutual organisations,
                    </li>
                        <li>
                            the data controller needs to process your personal information for the legitimate interests of: collecting your opinion and preferences on the options or candidates to be chosen or elected or motions or resolutions requiring approval
                    </li>
                        <li>
                            the processing is necessary for the performance of a contract to which you are party, e.g. the membership terms and conditions of an organisation may form a contract which requires the data controller to ensure you can vote.
                    </li>
                        <li>
                            the data controller needs to process your personal information in the exercise of official authority, e.g. NHS foundation trust elections.
                    </li>
                    </ol>
                    <p>
                        Personal information may also be collected and processed to determine what is most effective about this site, to help CES identify ways to improve it. If data is used for any other purposes, these will be described to you at the point the personal information is collected. The personal information collected is not used for marketing.
                    </p>
                    <h2>
                        What information is gathered via this site?
                    </h2>
                    <p>
                        Information in this site is gathered in two ways: indirectly (for example, through the site's technology); and directly (for example through information that you enter). Examples of information collected indirectly are your internet (IP) address which is automatically collected and is placed in internet access logs, and the date and time of when you access the site. Examples of information collected directly are the candidates or options for which you vote or information you provide about yourself. The personal information collected is only that which is sufficient and necessary for the purpose of joinIN events and all information is processed fairly and lawfully.
                    </p>
                    <p>
                        This website may also use cookies, which are small text files stored on your computer or device when you visit a website, which allow the website to work properly and help keep it secure, and help us to understand how people are using the website so that it can be improved. For more information about the cookies used, please read <a href="https://secure.cesvotes.com/V3-0-0/votedemo/en/cookie-information" target="_blank">Cookie Information.</a>
                    </p>
                    <h2>
                        How long will we keep this information?
                    </h2>
                    <p>
                        At the conclusion of the joinIN event, the personal information will be stored offline for a period of time, in case there are any queries or problems with individual votes or other data submissions. The information will not be kept for longer than relevant legislation permits, and will normally be erased no more than fourteen months after the project closes.
                    </p>
                    <h2>
                        Access to your details and other rights?
                    </h2>
                    <p>
                        You have the right to request personal information held and to have any inaccurate information, such as your name or contact details, corrected. You also have the right to object to processing on grounds relating to your particular situation. Such requests or objections should go to the data controller i.e. the client on whose behalf CES is conducting this joinIN event. You may also contact the CES Data Protection Officer via compliance@civica.co.uk, but please be aware that there may be a delay while your request is forwarded to the relevant data controller. Finally, if you are not happy with the way the data controller responds to your request or objection, you have the right to complain to the Information Commissioner at https://ico.org.uk/concerns/handling/.
                    </p>
                    <h2>
                        Will we share this information with outside parties?
                    </h2>
                    <p>
                        CES will not disclose personal information unless required by law, that is if such action is necessary to comply with a current judicial proceeding, a court order or legal process served on our website. Personal information collected will not be sold.
                    </p>
                    <h2>
                        What security controls are in place?
                    </h2>
                    <p>
                        The data controller and CES want you to be secure when visiting this site and are committed to maintaining your privacy when doing so. CES has physical security in its facilities to protect against the loss, theft, misuse, or alteration of information. There are also different layers of security implemented throughout the website platform, for example hardware and application firewalls; intrusion detection systems; and SSL encryption.
                    </p>
                    <p>
                        Copyright &#169; 2004-2022 Civica Election Services Limited (CESL). All rights reserved.
                    </p>
                    <p>
                        Unless otherwise stated, the contents of this site including, but not limited to, the text and images contained herein and their arrangement are the property of CESL. All trademarks used or referred to in this website are the property of their respective owners. Nothing contained in this site shall be construed as conferring by implication, estoppel, or otherwise, any license or right to any copyright, patent, trademark or other proprietary interest of CESL or any third party. This site and the content provided in this site, including, but not limited to, graphic images, audio, video, html code, buttons, and text, may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, without the prior written consent of CESL, except that you may download, display, and print one copy of the materials on any single computer solely for your personal, non-commercial use, provided that you do not modify the material in any way and you keep intact all copyright, trademark, and other proprietary notices.
                    </p>

                </div>
            </div>
        );
    }
}
