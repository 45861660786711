import React, { lazy, Suspense, useContext, useMemo, useEffect, Fragment } from 'react';
import UserContext from '../context/UserContext';
import { Loader } from '../SecondaryComponents';

export function ZoomClientWrapper(props) {
    const ZoomClient = lazy(() => import('./ZoomClient'));

    const { switches } = useContext(UserContext);
    const context = useContext(UserContext);

    useEffect(() => {
        context.populateMeetingAndUser();
        context.getSwitches();
    }, [])

    const ClientLoader = useMemo(() => {
        return (
            <Fragment>
                <Suspense fallback={<Loader />}>
                    <ZoomClient
                        meetingId={switches.zoomMeetingId}
                        meetingPasscode={switches.zoomMeetingPasscode}
                        userName={context.user.userName}

                    />
                </Suspense>
            </Fragment>
        )
    }, [switches])

    return ClientLoader

}