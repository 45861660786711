import React, { Component } from 'react';

export class Help extends Component {
    static displayName = Help.name;
    componentDidMount() {
        document.title = "CESJoinIN - Help"
    }

    render() {
        return (
            <div>
                <div className="cloud helppolicy">
                    <h1>Help/FAQ for users of the CESjoinIN service </h1>
                    <ol>
                        <li>
                            <p>
                                About CESjoinIN
                            </p>
                            <p>
                                CESjoinIN is a web-based service for stakeholder engagement and polling at live events or meetings. This Help page answers some frequently asked questions we receive about the CESjoinIN service.                         </p>
                        </li>
                        <li>
                            <p>
                                About Civica Election Services (CES)
                            </p>
                            <p>
                                Formerly known as Electoral Reform Services (ERS), we have over 100 years' experience of administering elections, ballots and consultation processes and are proud to be the UK's leading provider of election services.
                         </p>
                        </li>
                        <li>
                            <p>
                                I am having technical problems such as a connection issue or delay
                            </p>
                            <p>
                                There may be a local connectivity problem. Many problems are solved if you simply refresh your browser or logout and login again. If that does not resolve the issue then please close and reopen your browser or clear your browser cache. In more serious cases you may need to reboot your device or try connecting from a different device.
                               </p><p>
                                If you are using a workplace connection and have poor network performance or permission issues please contact your local technical resources.
                                </p><p>
                                This site should support most common browsers including all recent versions of Chrome, Safari, Firefox & Edge.
                                </p><p>
                                The site is optimised to work with most common mobile devices. If you experience any difficulties while using a smartphone or tablet to access the site please try from another device.
                                </p><p>
                                If you have any difficulties with a specific browser or device please let us know when you are asked for feedback at the end of the event.
                            </p>
                        </li>
                        <li>
                            <p>
                                I have a question about the arrangements for the event.
                            </p>
                            <p>
                                The notice you were sent with joining instructions for the event contains contact details so you can ask any questions you may have about specific arrangements for the meeting such as who can attend, agenda information, how you can participate in the event
                            </p>
                        </li>
                        <li>
                            <p>
                                I do not know my Meeting ID
                            </p>
                            <p>
                                Your meeting ID is a 5 digit number that can be found on the notice you were sent with joining instructions. 
                            </p>
                        </li>
                        <li>
                            <p>
                                I do not know my Security Code
                            </p>
                            <p>
                                There is a "Don't know your security code" function on the login page that will resend the details to you by email if your email address matches our database of eligible attendees. You will need to know your Meeting ID. 
                            </p>
                        </li>
                        <li>
                            <p>
                                The system won't accept my Meeting ID or Security Code.
                            </p>
                            <p>
                                Please double check that you have entered your Meeting ID or Security Code correctly (there should be no spaces). Your Security Code may have been prepopulated for you by a unique link contained in your notice email. 
                            </p>
                        </li>
                        <li>
                            <p>
                                The Meeting ID or Security Code has already been populated, should I continue?
                            </p>
                            <p>
                                If you have been emailed your joining instructions then we usually embed your Meeting ID and Security Code in a unique link. This saves you from having to copy the codes over from the email into the login page making it more convenient, but no less secure, to login. Each attendee's link is unique and will populate a different Security Code on the login page.
                            </p>
                        </li>
                        <li>
                            <p>
                                Why do you send both parts of my security code together, how is that secure?
                            </p>
                            <p>
                                Each attendee is given a unique two part Security Code to use to login. The Security Code is split into two parts to make it easier to read and to type in to the login page; it is not in two parts to make it more secure. The Security Code is single-use and so, unlike a password to other secure online transactions, serves no purpose once it has been used.
                            </p>
                        </li>
                        <li>
                            <p>
                                How secure is the CESjoinIN service?
                            </p>
                            <p>
                                We want you to be secure when visiting our site and we are committed to maintaining your privacy when doing so. Further information is available on our Policy Statement. CES has physical security in our facilities to protect against the loss, theft, misuse, or alteration of information. There are also different layers of security implemented throughout our website platform, for example hardware and application firewalls; intrusion detection systems; and SSL encryption.
                            </p>
                        </li>
                        <li>
                            <p>
                                I have been appointed as a proxy, what do I need to do?
                            </p>
                            <p>
                                If you are a member of the organisation the system should recognise your proxy appointment when you login. You will be asked to confirm that you agree to act as proxy and be given the opportunity to cast any discretionary votes. If you believe you have been appointed but the system does not recognise this please use the contact information given in the notice.
                            </p>
                        </li>
                        <li>
                            <p>
                                How do I cast my vote?
                            </p>
                            <p>
                                Notification banners will appear on your screen to inform you of activities relating to voting. At the point a poll opens you will find that the items and voting choices will automatically be displayed on your screen with instructions on how to cast your vote. 
                            </p>
                        </li>
                        <li>
                            <p>
                                I submitted a question to the meeting but it has not been answered yet.
                            </p>
                            <p>
                                Thank you for submitting your question. It may not be possible for the presenters to respond to all of them during the course of the event; it may be that you receive a written response during or after the event.
                            </p>
                        </li>
                        <li>
                            <p>
                            I am a visually impaired or disabled internet user and require special software or tools to browse the internet. Is the website compatible with my system?
                            </p>
                            <p>
                                We have tested the website on the most common browsers, and incorporated principles of accessibility into the website design wherever possible. If you encounter any difficulties, or have suggestions for improvement, please do give us your feedback when asked at the end of the event.
                            </p>
                        </li>
                        <li>
                            <p>
                                Further support
                            </p>
                            <p>
                                For your convenience we have prepared responses to the most frequently asked questions above so that you can get instant answers to any queries you may have. Please do check your question is not answered on this page before <a href="mailto:support@cesjoinin.com?subject=CESjoinIN%20Support%20Request">contacting us</a> for further support. 
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
        );
    }
}
