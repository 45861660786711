import React, { Component, Fragment } from 'react';
import UserContext from './context/UserContext';
import CloseButton from './../components/CloseButton'
import { Sanitise } from '../Common/Sanitiser';

export default class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            welcome: [],
            loading: true
        };
    }

    componentDidMount() {
        document.title = "CESJoinIN - Home";
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    renderWelcomeMessage = () => {
        let messageSource;
        if (this.context.fullState.attendanceStatus && this.context.switches.inRoomDifferentWelcome) {
            messageSource = this.context.meeting.welcomeMessageInRoom;
        } else {
            messageSource = this.context.meeting.welcomeMessage
        }
        //let sanitisedMessage = DOMPurify.sanitize(messageSource);
        //sanitisedMessage = sanitisedMessage.replace(/<a\shref=/g, "<a target='_blank'  href=");
        //const regex = /document./gi;
        //sanitisedMessage = sanitisedMessage.replaceAll(regex, "document. ");
        let sanitisedMessage = Sanitise(messageSource);


        return (
                <div className="cloudWelcome scrollContainer">
                    <div className="d-flex justify-content-end">
                        <CloseButton />
                    </div>
                        <div dangerouslySetInnerHTML={ sanitisedMessage } />
                </div>
        );

    }

    render() {
        let contents = this.context.fullState.contextLoading
            ? <Fragment>
                <div className="loader"></div> </Fragment>
            : this.renderWelcomeMessage();

        return (
            <div className="scrollFix">
                {contents}
            </div>
        );
    }
}

Home.contextType = UserContext;
