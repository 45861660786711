import React, { Fragment, useState, useContext, useEffect } from 'react';
import { faEllipsisH, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';
import { FlagForModerator } from './FlagForModerator';
import { DeleteOwnPost } from './JsFunctions/APIFunctions';
import { EditPost } from './EditPost';


//Three dots for edit, flag, delete etc.
export function ContextMenu(props) {
    const context = useContext(OpenChatContext);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [flagged, setFlagged] = useState(false);
    let post = props.post;
    let flagList = context.contextState.Flags;
    let searchedFlagList = flagList.find(a => a.PostId === post.PostId)

    useEffect(() => {
        if (searchedFlagList) {
            setFlagged(true)
        }
    }, [searchedFlagList])

    const handleClose = () => setShow(false);
    const handleEditClose = () => setShowEdit(false);
    const handleShow = () => setShow(true);
    const handleEditShow = () => setShowEdit(true);

    if (context.contextState.Muted !== "0") {
        return <Fragment />
    } else {
        return (
            <Fragment>
                <DropdownButton
                    size="sm"
                    drop="start"
                    variant="transparent"
                    title={flagged ?
                        <Fragment><span className="sr-only">Message options</span><FontAwesomeIcon icon={faFlag} color="red" /></Fragment>
                        : <Fragment><span className="sr-only">Message options</span><FontAwesomeIcon icon={faEllipsisH} /></Fragment>}
                    className="btn btn-sm p-0">
                    <Dropdown.Item
                        as="button"
                        className="sm small"
                        onClick={() => context.setReplyTarget(post.PostId)}>Reply
                    </Dropdown.Item>
                    {post.IsPostOwner ?
                        <Fragment>
                            <Dropdown.Item
                                className="sm small"
                                onClick={() => handleEditShow()}
                                as="button">Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="sm small"
                                onClick={() => context.OpenChatDeletePost(post.PostId)}
                                as="button">Delete
                            </Dropdown.Item>
                        </Fragment>
                        :
                        <Dropdown.Item as="button" className="sm small pb-0"
                            title={flagged ? "You have already reported this message" : "Report to moderator"}
                            disabled={flagged}
                            onClick={handleShow}>
                            Report
                        </Dropdown.Item>
                    }

                </DropdownButton>
                {show && <FlagForModerator show={show} handleClose={handleClose} post={post} />}
                {showEdit && <EditPost show={showEdit} handleClose={handleEditClose} post={post} />}
            </Fragment>
        )
    }
}

