import authService from '../../../_services/AuthService';


//Generic get all posts for this meeting. Also gets ownership flag.
export async function OpenChatGetAllPosts() {
    const token = authService.getToken();
    const response = await fetch('OpenChatGetAllPosts', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        //body: JSON.stringify(request)
    });

    let data = await response.json().then(data => {
        return JSON.parse(data.openChatJson);
    }).catch((error) => {
        return "Error";
    });
    return data;
}

//Get paged posts for this meeting. Also gets ownership flag.
export async function OpenChatGetPagedPosts(postId) {
    const token = authService.getToken();
    let request = {
        PostId: postId
    }
    const response = await fetch('OpenChatGetPagedPosts', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(request)
    });

    let data = await response.json().then(data => {
        return JSON.parse(data.openChatJson);
    }).catch((error) => {
        return "Error";
    });
    return data;
}

//Get single post.
export async function OpenChatGetSinglePost(postId) {
    const token = authService.getToken();
    let request = {
        PostId: postId
    }
    const response = await fetch('OpenChatGetSinglePost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(request)
    });

    let data = await response.json().then(data => {
        return JSON.parse(data.openChatJson);
    }).catch((error) => {
        return "Error";
    });
    return data;
}

//Add new post or reply to chat
export async function OpenChatNewPost(targetId, text) {
    let request = {
        PostContent: text,
        ParentPostId: targetId
    }
    const token = authService.getToken();
    const response = await fetch('AddNewMessage', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(request)
    });

    let data = await response.json().then(response => {
        return response;
    }).catch((error) => {
        return "Error";
    });
    return data;
}

//Edit own post
export async function OpenChatEditPost(targetId, text) {
    let request = {
        PostContent: text,
        PostId: targetId
    }
    const token = authService.getToken();
    const response = await fetch('EditMessage', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(request)
    });

    let data = await response.json().then(response => {
        return response;
    }).catch((error) => {
        return "Error";
    });
    return data;
}


//Delete(hide) own post
export async function DeleteOwnPost(targetId) {
    let request = {        
        PostId: targetId
    }
    const token = authService.getToken();
    const response = await fetch('DeleteOwnPost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(request)
    });

    let data = await response.json().then(response => {
        return response;
    }).catch((error) => {
        return "Error";
    });
    return data;
}


//Add new likes and dislikes to posts
export async function OpenChatNewLike(targetId, actionType) {
    let request = {
        PostId: targetId,
        ActionType: actionType
    }
    const token = authService.getToken();
    const response = await fetch('AddNewLike', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(request)
    });

    let data = await response.json().then(response => {
        return response;
    }).catch((error) => {
        return "Error";
    });
    return data;
}


//Gets likes and flags submitted by current user
export async function OpenChatGetMyActions() {
    const token = authService.getToken();
    const response = await fetch('OpenChatGetMyActions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        //body: JSON.stringify(request)
    });

    let data = await response.json().then(data => {
        return JSON.parse(data.openChatJson);
    }).catch((error) => {
        return "Error";
    });
    return data;
}


export async function OpenChatAcceptTerms() {
    const token = authService.getToken();
    const response = await fetch('OpenChatAcceptTerms', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        //body: JSON.stringify(request)
    });

    let data = await response.json().then(data => {
        return data;
    }).catch((error) => {
        return "Error";
    });
    return data;
}

/**
 * Function will get all the emojis for open chat
 * */
export async function OpenChatGetEmojis() {
    const token = authService.getToken();
    const response = await fetch('OpenChatGetEmojis', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        //body: JSON.stringify(request)
    });

    let data = await response.json().then(data => {
        return JSON.parse(data.openChatJson);
    }).catch((error) => {
        return "Error";
    });
    return data;
}